import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InitAuthenticationMutationVariables = Types.Exact<{
  input: Types.InitAuthenticationInput;
}>;


export type InitAuthenticationMutation = { __typename?: 'Mutation', initAuthentication: { __typename: 'SetPasswordRequired', session: string } | { __typename: 'UserAuthenticatedResponse', accessToken: string, idToken: string, refreshToken: string, expiresIn: number } };

export type CheckQueryVariables = Types.Exact<{
  input: Types.CheckInput;
}>;


export type CheckQuery = { __typename?: 'Query', check: { __typename?: 'UserCheckedResponse', nextStep: Types.AuthNextStep } };

export type ConfirmForgotPasswordMutationVariables = Types.Exact<{
  input: Types.ConfirmForgotPasswordInput;
}>;


export type ConfirmForgotPasswordMutation = { __typename?: 'Mutation', confirmForgotPassword: { __typename?: 'ConfirmForgotPasswordResponse', status: Types.ConfirmForgotPasswordResult } };

export type ForgotPasswordMutationVariables = Types.Exact<{
  input: Types.ForgotPasswordInput;
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: { __typename?: 'VerificationCodeSentResponse', deliveryDetails?: { __typename?: 'DeliveryDetails', channel?: Types.Channel | null, destination: string } | null } };

export type RefreshTokenMutationVariables = Types.Exact<{
  input: Types.RefreshTokenInput;
}>;


export type RefreshTokenMutation = { __typename?: 'Mutation', refreshToken: { __typename?: 'TokenRefreshedResponse', accessToken: string, idToken: string, expiresIn: number } };

export type ResendVerificationCodeMutationVariables = Types.Exact<{
  input: Types.ResendVerificationCodeInput;
}>;


export type ResendVerificationCodeMutation = { __typename?: 'Mutation', resendVerificationCode: { __typename?: 'VerificationCodeSentResponse', deliveryDetails?: { __typename: 'DeliveryDetails', channel?: Types.Channel | null, destination: string } | null } };

export type SetPasswordMutationVariables = Types.Exact<{
  input: Types.SetPasswordInput;
}>;


export type SetPasswordMutation = { __typename?: 'Mutation', setPassword: { __typename?: 'UserAuthenticatedResponse', accessToken: string, idToken: string, refreshToken: string, expiresIn: number } };

export type ResendVerificationCodeByUsersStoreMutationVariables = Types.Exact<{
  input: Types.ResendVerificationCodeByUsersStoreInput;
}>;


export type ResendVerificationCodeByUsersStoreMutation = { __typename?: 'Mutation', resendVerificationCodeByUsersStore: { __typename?: 'VerificationCodeSentResponse', deliveryDetails?: { __typename?: 'DeliveryDetails', channel?: Types.Channel | null } | null } };

export type AuthorizedDeviceAuthMutationVariables = Types.Exact<{
  input: Types.AuthorizedDeviceInput;
}>;


export type AuthorizedDeviceAuthMutation = { __typename?: 'Mutation', authorizedDeviceAuth: { __typename?: 'UserAuthenticatedResponse', accessToken: string, idToken: string, refreshToken: string, expiresIn: number } };


export const InitAuthenticationDocument = gql`
    mutation InitAuthentication($input: InitAuthenticationInput!) {
  initAuthentication(input: $input) {
    ... on UserAuthenticatedResponse {
      accessToken
      idToken
      refreshToken
      expiresIn
      __typename
    }
    ... on SetPasswordRequired {
      session
      __typename
    }
  }
}
    `;
export type InitAuthenticationMutationFn = Apollo.MutationFunction<InitAuthenticationMutation, InitAuthenticationMutationVariables>;

/**
 * __useInitAuthenticationMutation__
 *
 * To run a mutation, you first call `useInitAuthenticationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitAuthenticationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initAuthenticationMutation, { data, loading, error }] = useInitAuthenticationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitAuthenticationMutation(baseOptions?: Apollo.MutationHookOptions<InitAuthenticationMutation, InitAuthenticationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitAuthenticationMutation, InitAuthenticationMutationVariables>(InitAuthenticationDocument, options);
      }
export type InitAuthenticationMutationHookResult = ReturnType<typeof useInitAuthenticationMutation>;
export type InitAuthenticationMutationResult = Apollo.MutationResult<InitAuthenticationMutation>;
export type InitAuthenticationMutationOptions = Apollo.BaseMutationOptions<InitAuthenticationMutation, InitAuthenticationMutationVariables>;
export const CheckDocument = gql`
    query Check($input: CheckInput!) {
  check(input: $input) {
    nextStep
  }
}
    `;

/**
 * __useCheckQuery__
 *
 * To run a query within a React component, call `useCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckQuery(baseOptions: Apollo.QueryHookOptions<CheckQuery, CheckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckQuery, CheckQueryVariables>(CheckDocument, options);
      }
export function useCheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckQuery, CheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckQuery, CheckQueryVariables>(CheckDocument, options);
        }
export type CheckQueryHookResult = ReturnType<typeof useCheckQuery>;
export type CheckLazyQueryHookResult = ReturnType<typeof useCheckLazyQuery>;
export type CheckQueryResult = Apollo.QueryResult<CheckQuery, CheckQueryVariables>;
export const ConfirmForgotPasswordDocument = gql`
    mutation ConfirmForgotPassword($input: ConfirmForgotPasswordInput!) {
  confirmForgotPassword(input: $input) {
    status
  }
}
    `;
export type ConfirmForgotPasswordMutationFn = Apollo.MutationFunction<ConfirmForgotPasswordMutation, ConfirmForgotPasswordMutationVariables>;

/**
 * __useConfirmForgotPasswordMutation__
 *
 * To run a mutation, you first call `useConfirmForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmForgotPasswordMutation, { data, loading, error }] = useConfirmForgotPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmForgotPasswordMutation, ConfirmForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmForgotPasswordMutation, ConfirmForgotPasswordMutationVariables>(ConfirmForgotPasswordDocument, options);
      }
export type ConfirmForgotPasswordMutationHookResult = ReturnType<typeof useConfirmForgotPasswordMutation>;
export type ConfirmForgotPasswordMutationResult = Apollo.MutationResult<ConfirmForgotPasswordMutation>;
export type ConfirmForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ConfirmForgotPasswordMutation, ConfirmForgotPasswordMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($input: ForgotPasswordInput!) {
  forgotPassword(input: $input) {
    deliveryDetails {
      channel
      destination
    }
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation RefreshToken($input: RefreshTokenInput!) {
  refreshToken(input: $input) {
    accessToken
    idToken
    expiresIn
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const ResendVerificationCodeDocument = gql`
    mutation ResendVerificationCode($input: ResendVerificationCodeInput!) {
  resendVerificationCode(input: $input) {
    deliveryDetails {
      channel
      destination
      __typename
    }
  }
}
    `;
export type ResendVerificationCodeMutationFn = Apollo.MutationFunction<ResendVerificationCodeMutation, ResendVerificationCodeMutationVariables>;

/**
 * __useResendVerificationCodeMutation__
 *
 * To run a mutation, you first call `useResendVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerificationCodeMutation, { data, loading, error }] = useResendVerificationCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendVerificationCodeMutation(baseOptions?: Apollo.MutationHookOptions<ResendVerificationCodeMutation, ResendVerificationCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendVerificationCodeMutation, ResendVerificationCodeMutationVariables>(ResendVerificationCodeDocument, options);
      }
export type ResendVerificationCodeMutationHookResult = ReturnType<typeof useResendVerificationCodeMutation>;
export type ResendVerificationCodeMutationResult = Apollo.MutationResult<ResendVerificationCodeMutation>;
export type ResendVerificationCodeMutationOptions = Apollo.BaseMutationOptions<ResendVerificationCodeMutation, ResendVerificationCodeMutationVariables>;
export const SetPasswordDocument = gql`
    mutation SetPassword($input: SetPasswordInput!) {
  setPassword(input: $input) {
    accessToken
    idToken
    refreshToken
    expiresIn
  }
}
    `;
export type SetPasswordMutationFn = Apollo.MutationFunction<SetPasswordMutation, SetPasswordMutationVariables>;

/**
 * __useSetPasswordMutation__
 *
 * To run a mutation, you first call `useSetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPasswordMutation, { data, loading, error }] = useSetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<SetPasswordMutation, SetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPasswordMutation, SetPasswordMutationVariables>(SetPasswordDocument, options);
      }
export type SetPasswordMutationHookResult = ReturnType<typeof useSetPasswordMutation>;
export type SetPasswordMutationResult = Apollo.MutationResult<SetPasswordMutation>;
export type SetPasswordMutationOptions = Apollo.BaseMutationOptions<SetPasswordMutation, SetPasswordMutationVariables>;
export const ResendVerificationCodeByUsersStoreDocument = gql`
    mutation ResendVerificationCodeByUsersStore($input: ResendVerificationCodeByUsersStoreInput!) {
  resendVerificationCodeByUsersStore(input: $input) {
    deliveryDetails {
      channel
    }
  }
}
    `;
export type ResendVerificationCodeByUsersStoreMutationFn = Apollo.MutationFunction<ResendVerificationCodeByUsersStoreMutation, ResendVerificationCodeByUsersStoreMutationVariables>;

/**
 * __useResendVerificationCodeByUsersStoreMutation__
 *
 * To run a mutation, you first call `useResendVerificationCodeByUsersStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerificationCodeByUsersStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerificationCodeByUsersStoreMutation, { data, loading, error }] = useResendVerificationCodeByUsersStoreMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendVerificationCodeByUsersStoreMutation(baseOptions?: Apollo.MutationHookOptions<ResendVerificationCodeByUsersStoreMutation, ResendVerificationCodeByUsersStoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendVerificationCodeByUsersStoreMutation, ResendVerificationCodeByUsersStoreMutationVariables>(ResendVerificationCodeByUsersStoreDocument, options);
      }
export type ResendVerificationCodeByUsersStoreMutationHookResult = ReturnType<typeof useResendVerificationCodeByUsersStoreMutation>;
export type ResendVerificationCodeByUsersStoreMutationResult = Apollo.MutationResult<ResendVerificationCodeByUsersStoreMutation>;
export type ResendVerificationCodeByUsersStoreMutationOptions = Apollo.BaseMutationOptions<ResendVerificationCodeByUsersStoreMutation, ResendVerificationCodeByUsersStoreMutationVariables>;
export const AuthorizedDeviceAuthDocument = gql`
    mutation AuthorizedDeviceAuth($input: AuthorizedDeviceInput!) {
  authorizedDeviceAuth(input: $input) {
    accessToken
    idToken
    refreshToken
    expiresIn
  }
}
    `;
export type AuthorizedDeviceAuthMutationFn = Apollo.MutationFunction<AuthorizedDeviceAuthMutation, AuthorizedDeviceAuthMutationVariables>;

/**
 * __useAuthorizedDeviceAuthMutation__
 *
 * To run a mutation, you first call `useAuthorizedDeviceAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorizedDeviceAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorizedDeviceAuthMutation, { data, loading, error }] = useAuthorizedDeviceAuthMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthorizedDeviceAuthMutation(baseOptions?: Apollo.MutationHookOptions<AuthorizedDeviceAuthMutation, AuthorizedDeviceAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthorizedDeviceAuthMutation, AuthorizedDeviceAuthMutationVariables>(AuthorizedDeviceAuthDocument, options);
      }
export type AuthorizedDeviceAuthMutationHookResult = ReturnType<typeof useAuthorizedDeviceAuthMutation>;
export type AuthorizedDeviceAuthMutationResult = Apollo.MutationResult<AuthorizedDeviceAuthMutation>;
export type AuthorizedDeviceAuthMutationOptions = Apollo.BaseMutationOptions<AuthorizedDeviceAuthMutation, AuthorizedDeviceAuthMutationVariables>;
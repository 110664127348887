import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NotifyProductAvailabilityMutationVariables = Types.Exact<{
  productId: Types.Scalars['Int'];
}>;


export type NotifyProductAvailabilityMutation = { __typename?: 'Mutation', notifyProductAvailability: boolean };


export const NotifyProductAvailabilityDocument = gql`
    mutation NotifyProductAvailability($productId: Int!) {
  notifyProductAvailability(
    notifyProductAvailabilityInput: {productId: $productId}
  )
}
    `;
export type NotifyProductAvailabilityMutationFn = Apollo.MutationFunction<NotifyProductAvailabilityMutation, NotifyProductAvailabilityMutationVariables>;

/**
 * __useNotifyProductAvailabilityMutation__
 *
 * To run a mutation, you first call `useNotifyProductAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotifyProductAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notifyProductAvailabilityMutation, { data, loading, error }] = useNotifyProductAvailabilityMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useNotifyProductAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<NotifyProductAvailabilityMutation, NotifyProductAvailabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotifyProductAvailabilityMutation, NotifyProductAvailabilityMutationVariables>(NotifyProductAvailabilityDocument, options);
      }
export type NotifyProductAvailabilityMutationHookResult = ReturnType<typeof useNotifyProductAvailabilityMutation>;
export type NotifyProductAvailabilityMutationResult = Apollo.MutationResult<NotifyProductAvailabilityMutation>;
export type NotifyProductAvailabilityMutationOptions = Apollo.BaseMutationOptions<NotifyProductAvailabilityMutation, NotifyProductAvailabilityMutationVariables>;
import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindProductsQueryVariables = Types.Exact<{
  findProductsInput: Types.FindProductsInput;
}>;


export type FindProductsQuery = { __typename?: 'Query', findProducts: { __typename?: 'PagedProduct', products: Array<{ __typename?: 'Product', id: number, name: string, image: string, enabled: boolean, identificationCode: string, description: string, codeDUN?: string | null, codeUPC?: string | null, images: Array<{ __typename?: 'ProductImage', id: number, image: string, default: boolean }>, brand?: { __typename?: 'Brand', id: number, name: string, enabled: boolean, logo: string, supplier?: { __typename?: 'Supplier', id: number, name: string, type: Types.SupplierType } | null } | null, supplier?: { __typename?: 'Supplier', id: number, avatar?: string | null, name: string, type: Types.SupplierType } | null, category?: { __typename?: 'Category', id: number, name: string, enabled: boolean, supplierId: number, parentId?: number | null } | null, displayProducts?: Array<{ __typename?: 'DisplayProduct', id?: number | null, units: number, internalCode: string, default: boolean, enabled: boolean, codeDUN?: string | null, codeUPC?: string | null, productId?: number | null } | null> | null } | null>, pagination: { __typename?: 'PageInfo', pageBased?: { __typename?: 'PageBasedInfo', count: number } | null } } };

export type FindOneProductQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type FindOneProductQuery = { __typename?: 'Query', findByProductId?: { __typename?: 'Product', id: number, name: string, image: string, enabled: boolean, identificationCode: string, description: string, codeDUN?: string | null, codeUPC?: string | null, images: Array<{ __typename?: 'ProductImage', id: number, image: string, default: boolean }>, brand?: { __typename?: 'Brand', id: number, name: string, enabled: boolean, logo: string, supplier?: { __typename?: 'Supplier', id: number, name: string, avatar?: string | null, type: Types.SupplierType } | null } | null, supplier?: { __typename?: 'Supplier', id: number, avatar?: string | null, name: string, type: Types.SupplierType } | null, category?: { __typename?: 'Category', id: number, name: string, enabled: boolean, supplierId: number, parentId?: number | null } | null, displayProducts?: Array<{ __typename?: 'DisplayProduct', id?: number | null, units: number, internalCode: string, default: boolean, enabled: boolean, codeDUN?: string | null, codeUPC?: string | null, productId?: number | null } | null> | null } | null };

export type FindOneProductByInternalCodeQueryVariables = Types.Exact<{
  internalCode: Types.Scalars['String'];
}>;


export type FindOneProductByInternalCodeQuery = { __typename?: 'Query', findByInternalCode?: { __typename?: 'Product', id: number, name: string, image: string, enabled: boolean, identificationCode: string, description: string, codeDUN?: string | null, codeUPC?: string | null, displayProducts?: Array<{ __typename?: 'DisplayProduct', id?: number | null, units: number, internalCode: string, default: boolean, enabled: boolean, codeDUN?: string | null, codeUPC?: string | null, productId?: number | null } | null> | null } | null };

export type FindProductsByIdentificationCodeQueryVariables = Types.Exact<{
  identificationCodes: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type FindProductsByIdentificationCodeQuery = { __typename?: 'Query', findManyProductsByIdentificationCode?: Array<{ __typename?: 'Product', id: number, name: string, image: string, enabled: boolean, identificationCode: string, description: string, codeDUN?: string | null, codeUPC?: string | null, brand?: { __typename?: 'Brand', id: number, name: string, enabled: boolean, logo: string, supplier?: { __typename?: 'Supplier', id: number, name: string, type: Types.SupplierType } | null } | null, displayProducts?: Array<{ __typename?: 'DisplayProduct', id?: number | null, units: number, internalCode: string, default: boolean, enabled: boolean, codeDUN?: string | null, codeUPC?: string | null, productId?: number | null } | null> | null } | null> | null };

export type ChangeProductStatusMutationVariables = Types.Exact<{
  input: Types.ChangeProductStatusInput;
}>;


export type ChangeProductStatusMutation = { __typename?: 'Mutation', changeProductStatus: { __typename?: 'MutationProductResponse', product?: { __typename?: 'Product', id: number, name: string, image: string, enabled: boolean, identificationCode: string, description: string } | null } };

export type CreateProductMutationVariables = Types.Exact<{
  input: Types.CreateProductInput;
}>;


export type CreateProductMutation = { __typename?: 'Mutation', createProduct: { __typename?: 'MutationProductResponse', product?: { __typename?: 'Product', id: number, name: string, image: string, enabled: boolean, identificationCode: string, description: string, codeDUN?: string | null, codeUPC?: string | null, brand?: { __typename?: 'Brand', id: number, name: string, enabled: boolean, logo: string, supplier?: { __typename?: 'Supplier', id: number, name: string, type: Types.SupplierType } | null } | null, supplier?: { __typename?: 'Supplier', id: number } | null, category?: { __typename?: 'Category', id: number, name: string, enabled: boolean, supplierId: number, parentId?: number | null } | null, displayProducts?: Array<{ __typename?: 'DisplayProduct', id?: number | null, units: number, internalCode: string, default: boolean, enabled: boolean, codeDUN?: string | null, codeUPC?: string | null, productId?: number | null } | null> | null } | null } };

export type UpdateProductMutationVariables = Types.Exact<{
  input: Types.UpdateProductInput;
}>;


export type UpdateProductMutation = { __typename?: 'Mutation', updateProduct: { __typename?: 'MutationProductResponse', product?: { __typename?: 'Product', id: number, name: string, image: string, enabled: boolean, identificationCode: string, description: string, codeDUN?: string | null, codeUPC?: string | null, brand?: { __typename?: 'Brand', id: number, name: string, enabled: boolean, logo: string, supplier?: { __typename?: 'Supplier', id: number, name: string, type: Types.SupplierType } | null } | null, supplier?: { __typename?: 'Supplier', id: number } | null, category?: { __typename?: 'Category', id: number, name: string, enabled: boolean, supplierId: number, parentId?: number | null } | null, displayProducts?: Array<{ __typename?: 'DisplayProduct', id?: number | null, units: number, internalCode: string, default: boolean, enabled: boolean, codeDUN?: string | null, codeUPC?: string | null, productId?: number | null } | null> | null } | null } };

export type GetUrlForProductImageUploadQueryVariables = Types.Exact<{
  name: Types.Scalars['String'];
}>;


export type GetUrlForProductImageUploadQuery = { __typename?: 'Query', getUrlForProductImageUpload?: { __typename?: 'GetUrlForProductImageUploadResponse', url: string, key: string } | null };


export const FindProductsDocument = gql`
    query FindProducts($findProductsInput: FindProductsInput!) {
  findProducts(findProductsInput: $findProductsInput) {
    products {
      id
      name
      image
      images {
        id
        image
        default
      }
      enabled
      identificationCode
      description
      codeDUN
      codeUPC
      brand {
        id
        name
        enabled
        logo
        supplier {
          id
          name
          type
        }
      }
      supplier {
        id
        avatar
        name
        type
      }
      category {
        id
        name
        enabled
        supplierId
        parentId
      }
      displayProducts {
        id
        units
        internalCode
        default
        enabled
        codeDUN
        codeUPC
        productId
      }
    }
    pagination {
      pageBased {
        count
      }
    }
  }
}
    `;

/**
 * __useFindProductsQuery__
 *
 * To run a query within a React component, call `useFindProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindProductsQuery({
 *   variables: {
 *      findProductsInput: // value for 'findProductsInput'
 *   },
 * });
 */
export function useFindProductsQuery(baseOptions: Apollo.QueryHookOptions<FindProductsQuery, FindProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindProductsQuery, FindProductsQueryVariables>(FindProductsDocument, options);
      }
export function useFindProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindProductsQuery, FindProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindProductsQuery, FindProductsQueryVariables>(FindProductsDocument, options);
        }
export type FindProductsQueryHookResult = ReturnType<typeof useFindProductsQuery>;
export type FindProductsLazyQueryHookResult = ReturnType<typeof useFindProductsLazyQuery>;
export type FindProductsQueryResult = Apollo.QueryResult<FindProductsQuery, FindProductsQueryVariables>;
export const FindOneProductDocument = gql`
    query FindOneProduct($id: Int!) {
  findByProductId(id: $id) {
    id
    name
    image
    images {
      id
      image
      default
    }
    enabled
    identificationCode
    description
    codeDUN
    codeUPC
    brand {
      id
      name
      enabled
      logo
      supplier {
        id
        name
        avatar
        type
      }
    }
    supplier {
      id
      avatar
      name
      type
    }
    category {
      id
      name
      enabled
      supplierId
      parentId
    }
    displayProducts {
      id
      units
      internalCode
      default
      enabled
      codeDUN
      codeUPC
      productId
    }
  }
}
    `;

/**
 * __useFindOneProductQuery__
 *
 * To run a query within a React component, call `useFindOneProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOneProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOneProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindOneProductQuery(baseOptions: Apollo.QueryHookOptions<FindOneProductQuery, FindOneProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindOneProductQuery, FindOneProductQueryVariables>(FindOneProductDocument, options);
      }
export function useFindOneProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindOneProductQuery, FindOneProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindOneProductQuery, FindOneProductQueryVariables>(FindOneProductDocument, options);
        }
export type FindOneProductQueryHookResult = ReturnType<typeof useFindOneProductQuery>;
export type FindOneProductLazyQueryHookResult = ReturnType<typeof useFindOneProductLazyQuery>;
export type FindOneProductQueryResult = Apollo.QueryResult<FindOneProductQuery, FindOneProductQueryVariables>;
export const FindOneProductByInternalCodeDocument = gql`
    query FindOneProductByInternalCode($internalCode: String!) {
  findByInternalCode(internalCode: $internalCode) {
    id
    name
    image
    enabled
    identificationCode
    description
    codeDUN
    codeUPC
    displayProducts {
      id
      units
      internalCode
      default
      enabled
      codeDUN
      codeUPC
      productId
    }
  }
}
    `;

/**
 * __useFindOneProductByInternalCodeQuery__
 *
 * To run a query within a React component, call `useFindOneProductByInternalCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOneProductByInternalCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOneProductByInternalCodeQuery({
 *   variables: {
 *      internalCode: // value for 'internalCode'
 *   },
 * });
 */
export function useFindOneProductByInternalCodeQuery(baseOptions: Apollo.QueryHookOptions<FindOneProductByInternalCodeQuery, FindOneProductByInternalCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindOneProductByInternalCodeQuery, FindOneProductByInternalCodeQueryVariables>(FindOneProductByInternalCodeDocument, options);
      }
export function useFindOneProductByInternalCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindOneProductByInternalCodeQuery, FindOneProductByInternalCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindOneProductByInternalCodeQuery, FindOneProductByInternalCodeQueryVariables>(FindOneProductByInternalCodeDocument, options);
        }
export type FindOneProductByInternalCodeQueryHookResult = ReturnType<typeof useFindOneProductByInternalCodeQuery>;
export type FindOneProductByInternalCodeLazyQueryHookResult = ReturnType<typeof useFindOneProductByInternalCodeLazyQuery>;
export type FindOneProductByInternalCodeQueryResult = Apollo.QueryResult<FindOneProductByInternalCodeQuery, FindOneProductByInternalCodeQueryVariables>;
export const FindProductsByIdentificationCodeDocument = gql`
    query FindProductsByIdentificationCode($identificationCodes: [String!]!) {
  findManyProductsByIdentificationCode(identificationCodes: $identificationCodes) {
    id
    name
    image
    enabled
    identificationCode
    description
    codeDUN
    codeUPC
    brand {
      id
      name
      enabled
      logo
      supplier {
        id
        name
        type
      }
    }
    displayProducts {
      id
      units
      internalCode
      default
      enabled
      codeDUN
      codeUPC
      productId
    }
  }
}
    `;

/**
 * __useFindProductsByIdentificationCodeQuery__
 *
 * To run a query within a React component, call `useFindProductsByIdentificationCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindProductsByIdentificationCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindProductsByIdentificationCodeQuery({
 *   variables: {
 *      identificationCodes: // value for 'identificationCodes'
 *   },
 * });
 */
export function useFindProductsByIdentificationCodeQuery(baseOptions: Apollo.QueryHookOptions<FindProductsByIdentificationCodeQuery, FindProductsByIdentificationCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindProductsByIdentificationCodeQuery, FindProductsByIdentificationCodeQueryVariables>(FindProductsByIdentificationCodeDocument, options);
      }
export function useFindProductsByIdentificationCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindProductsByIdentificationCodeQuery, FindProductsByIdentificationCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindProductsByIdentificationCodeQuery, FindProductsByIdentificationCodeQueryVariables>(FindProductsByIdentificationCodeDocument, options);
        }
export type FindProductsByIdentificationCodeQueryHookResult = ReturnType<typeof useFindProductsByIdentificationCodeQuery>;
export type FindProductsByIdentificationCodeLazyQueryHookResult = ReturnType<typeof useFindProductsByIdentificationCodeLazyQuery>;
export type FindProductsByIdentificationCodeQueryResult = Apollo.QueryResult<FindProductsByIdentificationCodeQuery, FindProductsByIdentificationCodeQueryVariables>;
export const ChangeProductStatusDocument = gql`
    mutation ChangeProductStatus($input: ChangeProductStatusInput!) {
  changeProductStatus(changeProductStatusInput: $input) {
    product {
      id
      name
      image
      enabled
      identificationCode
      description
    }
  }
}
    `;
export type ChangeProductStatusMutationFn = Apollo.MutationFunction<ChangeProductStatusMutation, ChangeProductStatusMutationVariables>;

/**
 * __useChangeProductStatusMutation__
 *
 * To run a mutation, you first call `useChangeProductStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeProductStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeProductStatusMutation, { data, loading, error }] = useChangeProductStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeProductStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeProductStatusMutation, ChangeProductStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeProductStatusMutation, ChangeProductStatusMutationVariables>(ChangeProductStatusDocument, options);
      }
export type ChangeProductStatusMutationHookResult = ReturnType<typeof useChangeProductStatusMutation>;
export type ChangeProductStatusMutationResult = Apollo.MutationResult<ChangeProductStatusMutation>;
export type ChangeProductStatusMutationOptions = Apollo.BaseMutationOptions<ChangeProductStatusMutation, ChangeProductStatusMutationVariables>;
export const CreateProductDocument = gql`
    mutation CreateProduct($input: CreateProductInput!) {
  createProduct(createProductInput: $input) {
    product {
      id
      name
      image
      enabled
      identificationCode
      description
      codeDUN
      codeUPC
      brand {
        id
        name
        enabled
        logo
        supplier {
          id
          name
          type
        }
      }
      supplier {
        id
      }
      category {
        id
        name
        enabled
        supplierId
        parentId
      }
      displayProducts {
        id
        units
        internalCode
        default
        enabled
        codeDUN
        codeUPC
        productId
      }
    }
  }
}
    `;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, options);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const UpdateProductDocument = gql`
    mutation UpdateProduct($input: UpdateProductInput!) {
  updateProduct(updateProductInput: $input) {
    product {
      id
      name
      image
      enabled
      identificationCode
      description
      codeDUN
      codeUPC
      brand {
        id
        name
        enabled
        logo
        supplier {
          id
          name
          type
        }
      }
      supplier {
        id
      }
      category {
        id
        name
        enabled
        supplierId
        parentId
      }
      displayProducts {
        id
        units
        internalCode
        default
        enabled
        codeDUN
        codeUPC
        productId
      }
    }
  }
}
    `;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const GetUrlForProductImageUploadDocument = gql`
    query GetUrlForProductImageUpload($name: String!) {
  getUrlForProductImageUpload(name: $name) {
    url
    key
  }
}
    `;

/**
 * __useGetUrlForProductImageUploadQuery__
 *
 * To run a query within a React component, call `useGetUrlForProductImageUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUrlForProductImageUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUrlForProductImageUploadQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetUrlForProductImageUploadQuery(baseOptions: Apollo.QueryHookOptions<GetUrlForProductImageUploadQuery, GetUrlForProductImageUploadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUrlForProductImageUploadQuery, GetUrlForProductImageUploadQueryVariables>(GetUrlForProductImageUploadDocument, options);
      }
export function useGetUrlForProductImageUploadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUrlForProductImageUploadQuery, GetUrlForProductImageUploadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUrlForProductImageUploadQuery, GetUrlForProductImageUploadQueryVariables>(GetUrlForProductImageUploadDocument, options);
        }
export type GetUrlForProductImageUploadQueryHookResult = ReturnType<typeof useGetUrlForProductImageUploadQuery>;
export type GetUrlForProductImageUploadLazyQueryHookResult = ReturnType<typeof useGetUrlForProductImageUploadLazyQuery>;
export type GetUrlForProductImageUploadQueryResult = Apollo.QueryResult<GetUrlForProductImageUploadQuery, GetUrlForProductImageUploadQueryVariables>;
import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeDisplayProductStatusMutationVariables = Types.Exact<{
  input: Types.ChangeDisplayProductStatusInput;
}>;


export type ChangeDisplayProductStatusMutation = { __typename?: 'Mutation', changeDisplayProductStatus: { __typename?: 'MutationDisplayProductResponse', displayProduct?: { __typename?: 'DisplayProduct', id?: number | null, enabled: boolean, units: number, internalCode: string, productId?: number | null, default: boolean, codeDUN?: string | null, codeUPC?: string | null } | null } };

export type SetDisplayProductAsDefaultMutationVariables = Types.Exact<{
  input: Types.ConvertDisplayProductDefaultInput;
}>;


export type SetDisplayProductAsDefaultMutation = { __typename?: 'Mutation', convertDisplayProductDefault: { __typename?: 'MutationDisplayProductCovertResponse', displayProduct?: Array<{ __typename?: 'DisplayProduct', id?: number | null, enabled: boolean, units: number, internalCode: string, productId?: number | null, default: boolean, codeDUN?: string | null, codeUPC?: string | null } | null> | null } };


export const ChangeDisplayProductStatusDocument = gql`
    mutation ChangeDisplayProductStatus($input: ChangeDisplayProductStatusInput!) {
  changeDisplayProductStatus(changeDisplayProductStatusInput: $input) {
    displayProduct {
      id
      enabled
      units
      internalCode
      productId
      default
      codeDUN
      codeUPC
    }
  }
}
    `;
export type ChangeDisplayProductStatusMutationFn = Apollo.MutationFunction<ChangeDisplayProductStatusMutation, ChangeDisplayProductStatusMutationVariables>;

/**
 * __useChangeDisplayProductStatusMutation__
 *
 * To run a mutation, you first call `useChangeDisplayProductStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeDisplayProductStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeDisplayProductStatusMutation, { data, loading, error }] = useChangeDisplayProductStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeDisplayProductStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeDisplayProductStatusMutation, ChangeDisplayProductStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeDisplayProductStatusMutation, ChangeDisplayProductStatusMutationVariables>(ChangeDisplayProductStatusDocument, options);
      }
export type ChangeDisplayProductStatusMutationHookResult = ReturnType<typeof useChangeDisplayProductStatusMutation>;
export type ChangeDisplayProductStatusMutationResult = Apollo.MutationResult<ChangeDisplayProductStatusMutation>;
export type ChangeDisplayProductStatusMutationOptions = Apollo.BaseMutationOptions<ChangeDisplayProductStatusMutation, ChangeDisplayProductStatusMutationVariables>;
export const SetDisplayProductAsDefaultDocument = gql`
    mutation SetDisplayProductAsDefault($input: ConvertDisplayProductDefaultInput!) {
  convertDisplayProductDefault(convertDisplayProductDefaultInput: $input) {
    displayProduct {
      id
      enabled
      units
      internalCode
      productId
      default
      codeDUN
      codeUPC
    }
  }
}
    `;
export type SetDisplayProductAsDefaultMutationFn = Apollo.MutationFunction<SetDisplayProductAsDefaultMutation, SetDisplayProductAsDefaultMutationVariables>;

/**
 * __useSetDisplayProductAsDefaultMutation__
 *
 * To run a mutation, you first call `useSetDisplayProductAsDefaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDisplayProductAsDefaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDisplayProductAsDefaultMutation, { data, loading, error }] = useSetDisplayProductAsDefaultMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDisplayProductAsDefaultMutation(baseOptions?: Apollo.MutationHookOptions<SetDisplayProductAsDefaultMutation, SetDisplayProductAsDefaultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDisplayProductAsDefaultMutation, SetDisplayProductAsDefaultMutationVariables>(SetDisplayProductAsDefaultDocument, options);
      }
export type SetDisplayProductAsDefaultMutationHookResult = ReturnType<typeof useSetDisplayProductAsDefaultMutation>;
export type SetDisplayProductAsDefaultMutationResult = Apollo.MutationResult<SetDisplayProductAsDefaultMutation>;
export type SetDisplayProductAsDefaultMutationOptions = Apollo.BaseMutationOptions<SetDisplayProductAsDefaultMutation, SetDisplayProductAsDefaultMutationVariables>;
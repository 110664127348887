import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateLeadStoreMutationVariables = Types.Exact<{
  createLeadStoreInput: Types.CreateLeadStoreInput;
}>;


export type CreateLeadStoreMutation = { __typename?: 'Mutation', createLeadStore: { __typename?: 'MutationLeadStoreResponse', leadStore?: { __typename?: 'LeadStore', id: number, name: string, address?: string | null, zipCode?: string | null, appartmentNumber?: string | null, ownerName?: string | null, phone?: string | null, email?: string | null } | null } };

export type FindAllLeadStoresQueryVariables = Types.Exact<{
  findAllLeadStoresInput?: Types.InputMaybe<Types.FindAllLeadStoresInput>;
}>;


export type FindAllLeadStoresQuery = { __typename?: 'Query', findAllLeadStores: { __typename?: 'PagedLeadStore', leadStores: Array<{ __typename?: 'LeadStore', id: number, name: string, address?: string | null, zipCode?: string | null, appartmentNumber?: string | null, ownerName?: string | null, phone?: string | null, email?: string | null, createdAt: number } | null>, pagination: { __typename?: 'PageInfo', pageBased?: { __typename?: 'PageBasedInfo', count: number } | null } } };


export const CreateLeadStoreDocument = gql`
    mutation CreateLeadStore($createLeadStoreInput: CreateLeadStoreInput!) {
  createLeadStore(createLeadStoreInput: $createLeadStoreInput) {
    leadStore {
      id
      name
      address
      zipCode
      appartmentNumber
      ownerName
      phone
      email
    }
  }
}
    `;
export type CreateLeadStoreMutationFn = Apollo.MutationFunction<CreateLeadStoreMutation, CreateLeadStoreMutationVariables>;

/**
 * __useCreateLeadStoreMutation__
 *
 * To run a mutation, you first call `useCreateLeadStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLeadStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLeadStoreMutation, { data, loading, error }] = useCreateLeadStoreMutation({
 *   variables: {
 *      createLeadStoreInput: // value for 'createLeadStoreInput'
 *   },
 * });
 */
export function useCreateLeadStoreMutation(baseOptions?: Apollo.MutationHookOptions<CreateLeadStoreMutation, CreateLeadStoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLeadStoreMutation, CreateLeadStoreMutationVariables>(CreateLeadStoreDocument, options);
      }
export type CreateLeadStoreMutationHookResult = ReturnType<typeof useCreateLeadStoreMutation>;
export type CreateLeadStoreMutationResult = Apollo.MutationResult<CreateLeadStoreMutation>;
export type CreateLeadStoreMutationOptions = Apollo.BaseMutationOptions<CreateLeadStoreMutation, CreateLeadStoreMutationVariables>;
export const FindAllLeadStoresDocument = gql`
    query FindAllLeadStores($findAllLeadStoresInput: FindAllLeadStoresInput) {
  findAllLeadStores(findAllLeadStoresInput: $findAllLeadStoresInput) {
    leadStores {
      id
      name
      address
      zipCode
      appartmentNumber
      ownerName
      phone
      email
      createdAt
    }
    pagination {
      pageBased {
        count
      }
    }
  }
}
    `;

/**
 * __useFindAllLeadStoresQuery__
 *
 * To run a query within a React component, call `useFindAllLeadStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllLeadStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllLeadStoresQuery({
 *   variables: {
 *      findAllLeadStoresInput: // value for 'findAllLeadStoresInput'
 *   },
 * });
 */
export function useFindAllLeadStoresQuery(baseOptions?: Apollo.QueryHookOptions<FindAllLeadStoresQuery, FindAllLeadStoresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllLeadStoresQuery, FindAllLeadStoresQueryVariables>(FindAllLeadStoresDocument, options);
      }
export function useFindAllLeadStoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllLeadStoresQuery, FindAllLeadStoresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllLeadStoresQuery, FindAllLeadStoresQueryVariables>(FindAllLeadStoresDocument, options);
        }
export type FindAllLeadStoresQueryHookResult = ReturnType<typeof useFindAllLeadStoresQuery>;
export type FindAllLeadStoresLazyQueryHookResult = ReturnType<typeof useFindAllLeadStoresLazyQuery>;
export type FindAllLeadStoresQueryResult = Apollo.QueryResult<FindAllLeadStoresQuery, FindAllLeadStoresQueryVariables>;
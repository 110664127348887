import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindCommertialRoutesQueryVariables = Types.Exact<{
  input: Types.FindCommertialRoutesInput;
}>;


export type FindCommertialRoutesQuery = { __typename?: 'Query', findCommertialRoutes: { __typename?: 'PagedCommertialRoute', commertialRoutes: Array<{ __typename?: 'CommertialRoute', id: number, internalCode: string, name?: string | null, enabled: boolean, quantityStores?: number | null, salesRep?: { __typename?: 'SalesRep', id: number, name: string } | null }>, pagination: { __typename?: 'PageInfo', pageBased?: { __typename?: 'PageBasedInfo', count: number } | null } } };

export type FindOneCommertialRouteQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type FindOneCommertialRouteQuery = { __typename?: 'Query', findCommertialRouteById?: { __typename?: 'CommertialRoute', id: number, internalCode: string, name?: string | null, enabled: boolean, quantityStores?: number | null, salesRep?: { __typename?: 'SalesRep', id: number, name: string } | null } | null };

export type ChangeCommertialRouteStatusMutationVariables = Types.Exact<{
  input: Types.SetCommertialRouteStatusInput;
}>;


export type ChangeCommertialRouteStatusMutation = { __typename?: 'Mutation', setCommertialRouteStatus: { __typename?: 'MutationCommertialRouteResponse', commertialRoute: { __typename?: 'CommertialRoute', id: number, internalCode: string, name?: string | null, enabled: boolean } } };

export type CreateCommertialRouteMutationVariables = Types.Exact<{
  input: Types.CreateCommertialRouteInput;
}>;


export type CreateCommertialRouteMutation = { __typename?: 'Mutation', createCommertialRoute: { __typename?: 'MutationCommertialRouteResponse', commertialRoute: { __typename?: 'CommertialRoute', id: number, internalCode: string, name?: string | null, enabled: boolean } } };

export type UpdateCommertialRouteMutationVariables = Types.Exact<{
  input: Types.UpdateCommertialRouteInput;
}>;


export type UpdateCommertialRouteMutation = { __typename?: 'Mutation', updateCommertialRoute: { __typename?: 'MutationCommertialRouteResponse', commertialRoute: { __typename?: 'CommertialRoute', id: number, internalCode: string, name?: string | null, enabled: boolean } } };

export type AddStoreToCommertialRouteMutationVariables = Types.Exact<{
  input: Types.AddStoreToCommertialRouteInput;
}>;


export type AddStoreToCommertialRouteMutation = { __typename?: 'Mutation', addStoreToCommertialRoute: { __typename?: 'MutationCommertialRouteResponse', commertialRoute: { __typename?: 'CommertialRoute', id: number, internalCode: string, name?: string | null, enabled: boolean } } };

export type RemoveStoreFromCommertialRouteMutationVariables = Types.Exact<{
  input: Types.RemoveStoreFromCommertialRouteInput;
}>;


export type RemoveStoreFromCommertialRouteMutation = { __typename?: 'Mutation', removeStoreFromCommertialRoute: { __typename?: 'MutationCommertialRouteResponse', commertialRoute: { __typename?: 'CommertialRoute', id: number, internalCode: string, name?: string | null, enabled: boolean } } };

export type FindStoresInternalCodesInCommertialRouteQueryVariables = Types.Exact<{
  findStoresInternalCodesInCommertialRouteInput: Types.FindStoresInternalCodesInCommertialRouteInput;
}>;


export type FindStoresInternalCodesInCommertialRouteQuery = { __typename?: 'Query', findStoresInternalCodesInCommertialRoute: { __typename?: 'PagedStoreInternalCodes', storeInternalCodes: Array<string | null>, pagination: { __typename?: 'PageInfo', pageBased?: { __typename?: 'PageBasedInfo', count: number } | null } } };


export const FindCommertialRoutesDocument = gql`
    query FindCommertialRoutes($input: FindCommertialRoutesInput!) {
  findCommertialRoutes(findCommertialRoutesInput: $input) {
    commertialRoutes {
      id
      internalCode
      name
      enabled
      quantityStores
      salesRep {
        id
        name
      }
    }
    pagination {
      pageBased {
        count
      }
    }
  }
}
    `;

/**
 * __useFindCommertialRoutesQuery__
 *
 * To run a query within a React component, call `useFindCommertialRoutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCommertialRoutesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCommertialRoutesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindCommertialRoutesQuery(baseOptions: Apollo.QueryHookOptions<FindCommertialRoutesQuery, FindCommertialRoutesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindCommertialRoutesQuery, FindCommertialRoutesQueryVariables>(FindCommertialRoutesDocument, options);
      }
export function useFindCommertialRoutesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindCommertialRoutesQuery, FindCommertialRoutesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindCommertialRoutesQuery, FindCommertialRoutesQueryVariables>(FindCommertialRoutesDocument, options);
        }
export type FindCommertialRoutesQueryHookResult = ReturnType<typeof useFindCommertialRoutesQuery>;
export type FindCommertialRoutesLazyQueryHookResult = ReturnType<typeof useFindCommertialRoutesLazyQuery>;
export type FindCommertialRoutesQueryResult = Apollo.QueryResult<FindCommertialRoutesQuery, FindCommertialRoutesQueryVariables>;
export const FindOneCommertialRouteDocument = gql`
    query FindOneCommertialRoute($id: Int!) {
  findCommertialRouteById(id: $id) {
    id
    internalCode
    name
    enabled
    quantityStores
    salesRep {
      id
      name
    }
  }
}
    `;

/**
 * __useFindOneCommertialRouteQuery__
 *
 * To run a query within a React component, call `useFindOneCommertialRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOneCommertialRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOneCommertialRouteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindOneCommertialRouteQuery(baseOptions: Apollo.QueryHookOptions<FindOneCommertialRouteQuery, FindOneCommertialRouteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindOneCommertialRouteQuery, FindOneCommertialRouteQueryVariables>(FindOneCommertialRouteDocument, options);
      }
export function useFindOneCommertialRouteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindOneCommertialRouteQuery, FindOneCommertialRouteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindOneCommertialRouteQuery, FindOneCommertialRouteQueryVariables>(FindOneCommertialRouteDocument, options);
        }
export type FindOneCommertialRouteQueryHookResult = ReturnType<typeof useFindOneCommertialRouteQuery>;
export type FindOneCommertialRouteLazyQueryHookResult = ReturnType<typeof useFindOneCommertialRouteLazyQuery>;
export type FindOneCommertialRouteQueryResult = Apollo.QueryResult<FindOneCommertialRouteQuery, FindOneCommertialRouteQueryVariables>;
export const ChangeCommertialRouteStatusDocument = gql`
    mutation ChangeCommertialRouteStatus($input: SetCommertialRouteStatusInput!) {
  setCommertialRouteStatus(setCommertialRouteStatusInput: $input) {
    commertialRoute {
      id
      internalCode
      name
      enabled
    }
  }
}
    `;
export type ChangeCommertialRouteStatusMutationFn = Apollo.MutationFunction<ChangeCommertialRouteStatusMutation, ChangeCommertialRouteStatusMutationVariables>;

/**
 * __useChangeCommertialRouteStatusMutation__
 *
 * To run a mutation, you first call `useChangeCommertialRouteStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCommertialRouteStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCommertialRouteStatusMutation, { data, loading, error }] = useChangeCommertialRouteStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeCommertialRouteStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeCommertialRouteStatusMutation, ChangeCommertialRouteStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeCommertialRouteStatusMutation, ChangeCommertialRouteStatusMutationVariables>(ChangeCommertialRouteStatusDocument, options);
      }
export type ChangeCommertialRouteStatusMutationHookResult = ReturnType<typeof useChangeCommertialRouteStatusMutation>;
export type ChangeCommertialRouteStatusMutationResult = Apollo.MutationResult<ChangeCommertialRouteStatusMutation>;
export type ChangeCommertialRouteStatusMutationOptions = Apollo.BaseMutationOptions<ChangeCommertialRouteStatusMutation, ChangeCommertialRouteStatusMutationVariables>;
export const CreateCommertialRouteDocument = gql`
    mutation CreateCommertialRoute($input: CreateCommertialRouteInput!) {
  createCommertialRoute(createCommertialRouteInput: $input) {
    commertialRoute {
      id
      internalCode
      name
      enabled
    }
  }
}
    `;
export type CreateCommertialRouteMutationFn = Apollo.MutationFunction<CreateCommertialRouteMutation, CreateCommertialRouteMutationVariables>;

/**
 * __useCreateCommertialRouteMutation__
 *
 * To run a mutation, you first call `useCreateCommertialRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommertialRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommertialRouteMutation, { data, loading, error }] = useCreateCommertialRouteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommertialRouteMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommertialRouteMutation, CreateCommertialRouteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommertialRouteMutation, CreateCommertialRouteMutationVariables>(CreateCommertialRouteDocument, options);
      }
export type CreateCommertialRouteMutationHookResult = ReturnType<typeof useCreateCommertialRouteMutation>;
export type CreateCommertialRouteMutationResult = Apollo.MutationResult<CreateCommertialRouteMutation>;
export type CreateCommertialRouteMutationOptions = Apollo.BaseMutationOptions<CreateCommertialRouteMutation, CreateCommertialRouteMutationVariables>;
export const UpdateCommertialRouteDocument = gql`
    mutation UpdateCommertialRoute($input: UpdateCommertialRouteInput!) {
  updateCommertialRoute(updateCommertialRouteInput: $input) {
    commertialRoute {
      id
      internalCode
      name
      enabled
    }
  }
}
    `;
export type UpdateCommertialRouteMutationFn = Apollo.MutationFunction<UpdateCommertialRouteMutation, UpdateCommertialRouteMutationVariables>;

/**
 * __useUpdateCommertialRouteMutation__
 *
 * To run a mutation, you first call `useUpdateCommertialRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommertialRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommertialRouteMutation, { data, loading, error }] = useUpdateCommertialRouteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommertialRouteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommertialRouteMutation, UpdateCommertialRouteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommertialRouteMutation, UpdateCommertialRouteMutationVariables>(UpdateCommertialRouteDocument, options);
      }
export type UpdateCommertialRouteMutationHookResult = ReturnType<typeof useUpdateCommertialRouteMutation>;
export type UpdateCommertialRouteMutationResult = Apollo.MutationResult<UpdateCommertialRouteMutation>;
export type UpdateCommertialRouteMutationOptions = Apollo.BaseMutationOptions<UpdateCommertialRouteMutation, UpdateCommertialRouteMutationVariables>;
export const AddStoreToCommertialRouteDocument = gql`
    mutation AddStoreToCommertialRoute($input: AddStoreToCommertialRouteInput!) {
  addStoreToCommertialRoute(addStoreToCommertialRouteInput: $input) {
    commertialRoute {
      id
      internalCode
      name
      enabled
    }
  }
}
    `;
export type AddStoreToCommertialRouteMutationFn = Apollo.MutationFunction<AddStoreToCommertialRouteMutation, AddStoreToCommertialRouteMutationVariables>;

/**
 * __useAddStoreToCommertialRouteMutation__
 *
 * To run a mutation, you first call `useAddStoreToCommertialRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStoreToCommertialRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStoreToCommertialRouteMutation, { data, loading, error }] = useAddStoreToCommertialRouteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStoreToCommertialRouteMutation(baseOptions?: Apollo.MutationHookOptions<AddStoreToCommertialRouteMutation, AddStoreToCommertialRouteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStoreToCommertialRouteMutation, AddStoreToCommertialRouteMutationVariables>(AddStoreToCommertialRouteDocument, options);
      }
export type AddStoreToCommertialRouteMutationHookResult = ReturnType<typeof useAddStoreToCommertialRouteMutation>;
export type AddStoreToCommertialRouteMutationResult = Apollo.MutationResult<AddStoreToCommertialRouteMutation>;
export type AddStoreToCommertialRouteMutationOptions = Apollo.BaseMutationOptions<AddStoreToCommertialRouteMutation, AddStoreToCommertialRouteMutationVariables>;
export const RemoveStoreFromCommertialRouteDocument = gql`
    mutation RemoveStoreFromCommertialRoute($input: RemoveStoreFromCommertialRouteInput!) {
  removeStoreFromCommertialRoute(removeStoreFromCommertialRouteInput: $input) {
    commertialRoute {
      id
      internalCode
      name
      enabled
    }
  }
}
    `;
export type RemoveStoreFromCommertialRouteMutationFn = Apollo.MutationFunction<RemoveStoreFromCommertialRouteMutation, RemoveStoreFromCommertialRouteMutationVariables>;

/**
 * __useRemoveStoreFromCommertialRouteMutation__
 *
 * To run a mutation, you first call `useRemoveStoreFromCommertialRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStoreFromCommertialRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStoreFromCommertialRouteMutation, { data, loading, error }] = useRemoveStoreFromCommertialRouteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveStoreFromCommertialRouteMutation(baseOptions?: Apollo.MutationHookOptions<RemoveStoreFromCommertialRouteMutation, RemoveStoreFromCommertialRouteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveStoreFromCommertialRouteMutation, RemoveStoreFromCommertialRouteMutationVariables>(RemoveStoreFromCommertialRouteDocument, options);
      }
export type RemoveStoreFromCommertialRouteMutationHookResult = ReturnType<typeof useRemoveStoreFromCommertialRouteMutation>;
export type RemoveStoreFromCommertialRouteMutationResult = Apollo.MutationResult<RemoveStoreFromCommertialRouteMutation>;
export type RemoveStoreFromCommertialRouteMutationOptions = Apollo.BaseMutationOptions<RemoveStoreFromCommertialRouteMutation, RemoveStoreFromCommertialRouteMutationVariables>;
export const FindStoresInternalCodesInCommertialRouteDocument = gql`
    query FindStoresInternalCodesInCommertialRoute($findStoresInternalCodesInCommertialRouteInput: FindStoresInternalCodesInCommertialRouteInput!) {
  findStoresInternalCodesInCommertialRoute(
    findStoresInternalCodesInCommertialRouteInput: $findStoresInternalCodesInCommertialRouteInput
  ) {
    storeInternalCodes
    pagination {
      pageBased {
        count
      }
    }
  }
}
    `;

/**
 * __useFindStoresInternalCodesInCommertialRouteQuery__
 *
 * To run a query within a React component, call `useFindStoresInternalCodesInCommertialRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindStoresInternalCodesInCommertialRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindStoresInternalCodesInCommertialRouteQuery({
 *   variables: {
 *      findStoresInternalCodesInCommertialRouteInput: // value for 'findStoresInternalCodesInCommertialRouteInput'
 *   },
 * });
 */
export function useFindStoresInternalCodesInCommertialRouteQuery(baseOptions: Apollo.QueryHookOptions<FindStoresInternalCodesInCommertialRouteQuery, FindStoresInternalCodesInCommertialRouteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindStoresInternalCodesInCommertialRouteQuery, FindStoresInternalCodesInCommertialRouteQueryVariables>(FindStoresInternalCodesInCommertialRouteDocument, options);
      }
export function useFindStoresInternalCodesInCommertialRouteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindStoresInternalCodesInCommertialRouteQuery, FindStoresInternalCodesInCommertialRouteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindStoresInternalCodesInCommertialRouteQuery, FindStoresInternalCodesInCommertialRouteQueryVariables>(FindStoresInternalCodesInCommertialRouteDocument, options);
        }
export type FindStoresInternalCodesInCommertialRouteQueryHookResult = ReturnType<typeof useFindStoresInternalCodesInCommertialRouteQuery>;
export type FindStoresInternalCodesInCommertialRouteLazyQueryHookResult = ReturnType<typeof useFindStoresInternalCodesInCommertialRouteLazyQuery>;
export type FindStoresInternalCodesInCommertialRouteQueryResult = Apollo.QueryResult<FindStoresInternalCodesInCommertialRouteQuery, FindStoresInternalCodesInCommertialRouteQueryVariables>;
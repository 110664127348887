import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SimulateOrderMutationVariables = Types.Exact<{
  simulateOrderInput: Types.SimulateOrderInput;
}>;


export type SimulateOrderMutation = { __typename?: 'Mutation', simulateOrder: { __typename?: 'SimulateOrderResponse', price: { __typename?: 'Money', amount: number, currency: string, scale: number } } };

export type CreateOrderMutationVariables = Types.Exact<{
  createOrderInput: Types.CreateOrderInput;
}>;


export type CreateOrderMutation = { __typename?: 'Mutation', createOrder: { __typename?: 'MutationOrderResponse', order: { __typename?: 'Order', id: number, status: string } } };


export const SimulateOrderDocument = gql`
    mutation SimulateOrder($simulateOrderInput: SimulateOrderInput!) {
  simulateOrder(simulateOrderInput: $simulateOrderInput) {
    price {
      amount
      currency
      scale
    }
  }
}
    `;
export type SimulateOrderMutationFn = Apollo.MutationFunction<SimulateOrderMutation, SimulateOrderMutationVariables>;

/**
 * __useSimulateOrderMutation__
 *
 * To run a mutation, you first call `useSimulateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSimulateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [simulateOrderMutation, { data, loading, error }] = useSimulateOrderMutation({
 *   variables: {
 *      simulateOrderInput: // value for 'simulateOrderInput'
 *   },
 * });
 */
export function useSimulateOrderMutation(baseOptions?: Apollo.MutationHookOptions<SimulateOrderMutation, SimulateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SimulateOrderMutation, SimulateOrderMutationVariables>(SimulateOrderDocument, options);
      }
export type SimulateOrderMutationHookResult = ReturnType<typeof useSimulateOrderMutation>;
export type SimulateOrderMutationResult = Apollo.MutationResult<SimulateOrderMutation>;
export type SimulateOrderMutationOptions = Apollo.BaseMutationOptions<SimulateOrderMutation, SimulateOrderMutationVariables>;
export const CreateOrderDocument = gql`
    mutation CreateOrder($createOrderInput: CreateOrderInput!) {
  createOrder(createOrderInput: $createOrderInput) {
    order {
      id
      status
    }
  }
}
    `;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      createOrderInput: // value for 'createOrderInput'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindSupplierSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FindSupplierSettingsQuery = { __typename?: 'Query', findSupplierSettings: { __typename?: 'Setting', supportContactEmail?: string | null, supportContactPhone?: string | null } };

export type FindSupplierProfileQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FindSupplierProfileQuery = { __typename?: 'Query', findSupplierProfile: { __typename?: 'FindSupplierProfileResponse', loggedUser: { __typename?: 'User', id: number, sub: string, userType: Types.UserType, username: string, name?: string | null, loginMethod: Types.LoginMethodType, status: Types.UserStatusType, supplierId: number, countryCode?: string | null, phone?: string | null, platform?: Types.Platform | null }, supplier: { __typename?: 'Supplier', id: number, name: string, avatar?: string | null, type: Types.SupplierType } } };

export type UpsertStoreSettingsMutationVariables = Types.Exact<{
  input: Types.UpsertStoreSettingsInput;
}>;


export type UpsertStoreSettingsMutation = { __typename?: 'Mutation', upsertStoreSettings?: { __typename?: 'MutationSettingResponse', setting: { __typename?: 'Setting', supportContactEmail?: string | null, supportContactPhone?: string | null } } | null };


export const FindSupplierSettingsDocument = gql`
    query FindSupplierSettings {
  findSupplierSettings {
    supportContactEmail
    supportContactPhone
  }
}
    `;

/**
 * __useFindSupplierSettingsQuery__
 *
 * To run a query within a React component, call `useFindSupplierSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSupplierSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSupplierSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindSupplierSettingsQuery(baseOptions?: Apollo.QueryHookOptions<FindSupplierSettingsQuery, FindSupplierSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindSupplierSettingsQuery, FindSupplierSettingsQueryVariables>(FindSupplierSettingsDocument, options);
      }
export function useFindSupplierSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindSupplierSettingsQuery, FindSupplierSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindSupplierSettingsQuery, FindSupplierSettingsQueryVariables>(FindSupplierSettingsDocument, options);
        }
export type FindSupplierSettingsQueryHookResult = ReturnType<typeof useFindSupplierSettingsQuery>;
export type FindSupplierSettingsLazyQueryHookResult = ReturnType<typeof useFindSupplierSettingsLazyQuery>;
export type FindSupplierSettingsQueryResult = Apollo.QueryResult<FindSupplierSettingsQuery, FindSupplierSettingsQueryVariables>;
export const FindSupplierProfileDocument = gql`
    query FindSupplierProfile {
  findSupplierProfile {
    loggedUser {
      id
      sub
      userType
      username
      name
      loginMethod
      status
      supplierId
      countryCode
      phone
      platform
    }
    supplier {
      id
      name
      avatar
      type
    }
  }
}
    `;

/**
 * __useFindSupplierProfileQuery__
 *
 * To run a query within a React component, call `useFindSupplierProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSupplierProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSupplierProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindSupplierProfileQuery(baseOptions?: Apollo.QueryHookOptions<FindSupplierProfileQuery, FindSupplierProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindSupplierProfileQuery, FindSupplierProfileQueryVariables>(FindSupplierProfileDocument, options);
      }
export function useFindSupplierProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindSupplierProfileQuery, FindSupplierProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindSupplierProfileQuery, FindSupplierProfileQueryVariables>(FindSupplierProfileDocument, options);
        }
export type FindSupplierProfileQueryHookResult = ReturnType<typeof useFindSupplierProfileQuery>;
export type FindSupplierProfileLazyQueryHookResult = ReturnType<typeof useFindSupplierProfileLazyQuery>;
export type FindSupplierProfileQueryResult = Apollo.QueryResult<FindSupplierProfileQuery, FindSupplierProfileQueryVariables>;
export const UpsertStoreSettingsDocument = gql`
    mutation UpsertStoreSettings($input: UpsertStoreSettingsInput!) {
  upsertStoreSettings(input: $input) {
    setting {
      supportContactEmail
      supportContactPhone
    }
  }
}
    `;
export type UpsertStoreSettingsMutationFn = Apollo.MutationFunction<UpsertStoreSettingsMutation, UpsertStoreSettingsMutationVariables>;

/**
 * __useUpsertStoreSettingsMutation__
 *
 * To run a mutation, you first call `useUpsertStoreSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertStoreSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertStoreSettingsMutation, { data, loading, error }] = useUpsertStoreSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertStoreSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpsertStoreSettingsMutation, UpsertStoreSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertStoreSettingsMutation, UpsertStoreSettingsMutationVariables>(UpsertStoreSettingsDocument, options);
      }
export type UpsertStoreSettingsMutationHookResult = ReturnType<typeof useUpsertStoreSettingsMutation>;
export type UpsertStoreSettingsMutationResult = Apollo.MutationResult<UpsertStoreSettingsMutation>;
export type UpsertStoreSettingsMutationOptions = Apollo.BaseMutationOptions<UpsertStoreSettingsMutation, UpsertStoreSettingsMutationVariables>;
import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateSalesRepMutationVariables = Types.Exact<{
  input: Types.CreateSalesRepInput;
}>;


export type CreateSalesRepMutation = { __typename?: 'Mutation', createSalesRep: { __typename?: 'MutationSalesRepResponse', salesRep: { __typename?: 'SalesRep', id: number, name: string, username: string, enabled: boolean } } };

export type UpdateSalesRepMutationVariables = Types.Exact<{
  input: Types.UpdateSalesRepInput;
}>;


export type UpdateSalesRepMutation = { __typename?: 'Mutation', updateSalesRep: { __typename?: 'MutationSalesRepResponse', salesRep: { __typename?: 'SalesRep', id: number, name: string, username: string, enabled: boolean } } };

export type SetSalesRepStatusMutationVariables = Types.Exact<{
  input: Types.SetSalesRepStatusInput;
}>;


export type SetSalesRepStatusMutation = { __typename?: 'Mutation', setSalesRepStatus: { __typename?: 'MutationSalesRepResponse', salesRep: { __typename?: 'SalesRep', id: number, name: string, username: string, enabled: boolean } } };

export type FindSalesRepsQueryVariables = Types.Exact<{
  input: Types.FindSalesRepsInput;
}>;


export type FindSalesRepsQuery = { __typename?: 'Query', findSalesReps: { __typename?: 'PagedSalesRep', salesReps: Array<{ __typename?: 'SalesRep', id: number, name: string, username: string, enabled: boolean, countryCode: string, phone: string, commertialRoute?: { __typename?: 'CommertialRouteData', id: number, enabled: boolean, internalCode: string, name?: string | null } | null }>, pagination: { __typename?: 'PageInfo', pageBased?: { __typename?: 'PageBasedInfo', count: number } | null } } };

export type FindAllSalesRepsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FindAllSalesRepsQuery = { __typename?: 'Query', findAllSalesReps: Array<{ __typename?: 'SalesRep', id: number, name: string, username: string, enabled: boolean } | null> };

export type FindOneSalesRepQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type FindOneSalesRepQuery = { __typename?: 'Query', findSalesRepById?: { __typename?: 'SalesRep', id: number, name: string, username: string, enabled: boolean, countryCode: string, phone: string, commertialRoute?: { __typename?: 'CommertialRouteData', id: number, enabled: boolean, internalCode: string, name?: string | null } | null } | null };


export const CreateSalesRepDocument = gql`
    mutation CreateSalesRep($input: CreateSalesRepInput!) {
  createSalesRep(createSalesRepInput: $input) {
    salesRep {
      id
      name
      username
      enabled
    }
  }
}
    `;
export type CreateSalesRepMutationFn = Apollo.MutationFunction<CreateSalesRepMutation, CreateSalesRepMutationVariables>;

/**
 * __useCreateSalesRepMutation__
 *
 * To run a mutation, you first call `useCreateSalesRepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSalesRepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSalesRepMutation, { data, loading, error }] = useCreateSalesRepMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSalesRepMutation(baseOptions?: Apollo.MutationHookOptions<CreateSalesRepMutation, CreateSalesRepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSalesRepMutation, CreateSalesRepMutationVariables>(CreateSalesRepDocument, options);
      }
export type CreateSalesRepMutationHookResult = ReturnType<typeof useCreateSalesRepMutation>;
export type CreateSalesRepMutationResult = Apollo.MutationResult<CreateSalesRepMutation>;
export type CreateSalesRepMutationOptions = Apollo.BaseMutationOptions<CreateSalesRepMutation, CreateSalesRepMutationVariables>;
export const UpdateSalesRepDocument = gql`
    mutation UpdateSalesRep($input: UpdateSalesRepInput!) {
  updateSalesRep(updateSalesRepInput: $input) {
    salesRep {
      id
      name
      username
      enabled
    }
  }
}
    `;
export type UpdateSalesRepMutationFn = Apollo.MutationFunction<UpdateSalesRepMutation, UpdateSalesRepMutationVariables>;

/**
 * __useUpdateSalesRepMutation__
 *
 * To run a mutation, you first call `useUpdateSalesRepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSalesRepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSalesRepMutation, { data, loading, error }] = useUpdateSalesRepMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSalesRepMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSalesRepMutation, UpdateSalesRepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSalesRepMutation, UpdateSalesRepMutationVariables>(UpdateSalesRepDocument, options);
      }
export type UpdateSalesRepMutationHookResult = ReturnType<typeof useUpdateSalesRepMutation>;
export type UpdateSalesRepMutationResult = Apollo.MutationResult<UpdateSalesRepMutation>;
export type UpdateSalesRepMutationOptions = Apollo.BaseMutationOptions<UpdateSalesRepMutation, UpdateSalesRepMutationVariables>;
export const SetSalesRepStatusDocument = gql`
    mutation SetSalesRepStatus($input: SetSalesRepStatusInput!) {
  setSalesRepStatus(setSalesRepStatusInput: $input) {
    salesRep {
      id
      name
      username
      enabled
    }
  }
}
    `;
export type SetSalesRepStatusMutationFn = Apollo.MutationFunction<SetSalesRepStatusMutation, SetSalesRepStatusMutationVariables>;

/**
 * __useSetSalesRepStatusMutation__
 *
 * To run a mutation, you first call `useSetSalesRepStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSalesRepStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSalesRepStatusMutation, { data, loading, error }] = useSetSalesRepStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetSalesRepStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetSalesRepStatusMutation, SetSalesRepStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSalesRepStatusMutation, SetSalesRepStatusMutationVariables>(SetSalesRepStatusDocument, options);
      }
export type SetSalesRepStatusMutationHookResult = ReturnType<typeof useSetSalesRepStatusMutation>;
export type SetSalesRepStatusMutationResult = Apollo.MutationResult<SetSalesRepStatusMutation>;
export type SetSalesRepStatusMutationOptions = Apollo.BaseMutationOptions<SetSalesRepStatusMutation, SetSalesRepStatusMutationVariables>;
export const FindSalesRepsDocument = gql`
    query FindSalesReps($input: FindSalesRepsInput!) {
  findSalesReps(findSalesRepsInput: $input) {
    salesReps {
      id
      name
      username
      enabled
      countryCode
      phone
      commertialRoute {
        id
        enabled
        internalCode
        name
      }
    }
    pagination {
      pageBased {
        count
      }
    }
  }
}
    `;

/**
 * __useFindSalesRepsQuery__
 *
 * To run a query within a React component, call `useFindSalesRepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSalesRepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSalesRepsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindSalesRepsQuery(baseOptions: Apollo.QueryHookOptions<FindSalesRepsQuery, FindSalesRepsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindSalesRepsQuery, FindSalesRepsQueryVariables>(FindSalesRepsDocument, options);
      }
export function useFindSalesRepsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindSalesRepsQuery, FindSalesRepsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindSalesRepsQuery, FindSalesRepsQueryVariables>(FindSalesRepsDocument, options);
        }
export type FindSalesRepsQueryHookResult = ReturnType<typeof useFindSalesRepsQuery>;
export type FindSalesRepsLazyQueryHookResult = ReturnType<typeof useFindSalesRepsLazyQuery>;
export type FindSalesRepsQueryResult = Apollo.QueryResult<FindSalesRepsQuery, FindSalesRepsQueryVariables>;
export const FindAllSalesRepsDocument = gql`
    query FindAllSalesReps {
  findAllSalesReps {
    id
    name
    username
    enabled
  }
}
    `;

/**
 * __useFindAllSalesRepsQuery__
 *
 * To run a query within a React component, call `useFindAllSalesRepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllSalesRepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllSalesRepsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindAllSalesRepsQuery(baseOptions?: Apollo.QueryHookOptions<FindAllSalesRepsQuery, FindAllSalesRepsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllSalesRepsQuery, FindAllSalesRepsQueryVariables>(FindAllSalesRepsDocument, options);
      }
export function useFindAllSalesRepsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllSalesRepsQuery, FindAllSalesRepsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllSalesRepsQuery, FindAllSalesRepsQueryVariables>(FindAllSalesRepsDocument, options);
        }
export type FindAllSalesRepsQueryHookResult = ReturnType<typeof useFindAllSalesRepsQuery>;
export type FindAllSalesRepsLazyQueryHookResult = ReturnType<typeof useFindAllSalesRepsLazyQuery>;
export type FindAllSalesRepsQueryResult = Apollo.QueryResult<FindAllSalesRepsQuery, FindAllSalesRepsQueryVariables>;
export const FindOneSalesRepDocument = gql`
    query FindOneSalesRep($id: Int!) {
  findSalesRepById(id: $id) {
    id
    name
    username
    enabled
    countryCode
    phone
    commertialRoute {
      id
      enabled
      internalCode
      name
    }
  }
}
    `;

/**
 * __useFindOneSalesRepQuery__
 *
 * To run a query within a React component, call `useFindOneSalesRepQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOneSalesRepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOneSalesRepQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindOneSalesRepQuery(baseOptions: Apollo.QueryHookOptions<FindOneSalesRepQuery, FindOneSalesRepQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindOneSalesRepQuery, FindOneSalesRepQueryVariables>(FindOneSalesRepDocument, options);
      }
export function useFindOneSalesRepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindOneSalesRepQuery, FindOneSalesRepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindOneSalesRepQuery, FindOneSalesRepQueryVariables>(FindOneSalesRepDocument, options);
        }
export type FindOneSalesRepQueryHookResult = ReturnType<typeof useFindOneSalesRepQuery>;
export type FindOneSalesRepLazyQueryHookResult = ReturnType<typeof useFindOneSalesRepLazyQuery>;
export type FindOneSalesRepQueryResult = Apollo.QueryResult<FindOneSalesRepQuery, FindOneSalesRepQueryVariables>;
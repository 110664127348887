import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindStockGroupsQueryVariables = Types.Exact<{
  input: Types.FindStockGroupsInput;
}>;


export type FindStockGroupsQuery = { __typename?: 'Query', findStockGroups: { __typename?: 'PagedStockGroup', stockGroups: Array<{ __typename?: 'StockGroup', id: number, name: string, internalCode: string, enabled: boolean } | null>, pagination: { __typename?: 'PageInfo', pageBased?: { __typename?: 'PageBasedInfo', count: number } | null } } };

export type FindStoresInternalCodesInStockGroupQueryVariables = Types.Exact<{
  input: Types.FindStoresInternalCodesInStockGroupInput;
}>;


export type FindStoresInternalCodesInStockGroupQuery = { __typename?: 'Query', findStoresInternalCodesInStockGroup: { __typename?: 'PagedStoreInternalCodes', storeInternalCodes: Array<string | null>, pagination: { __typename?: 'PageInfo', cursorBased?: { __typename?: 'CursorBasedInfo', firstCursor: string, lastCursor: string, hasNextPage: boolean, hasPreviousPage: boolean } | null, pageBased?: { __typename?: 'PageBasedInfo', count: number } | null } } };

export type CreateStockGroupMutationVariables = Types.Exact<{
  input: Types.CreateStockGroupInput;
}>;


export type CreateStockGroupMutation = { __typename?: 'Mutation', createStockGroup: { __typename?: 'MutationStockGroupResponse', stockGroup?: { __typename?: 'StockGroup', id: number, name: string, internalCode: string, enabled: boolean } | null } };

export type UpdateStockGroupMutationVariables = Types.Exact<{
  input: Types.UpdateStockGroupInput;
}>;


export type UpdateStockGroupMutation = { __typename?: 'Mutation', updateStockGroup: { __typename?: 'MutationStockGroupResponse', stockGroup?: { __typename?: 'StockGroup', id: number, name: string, internalCode: string, enabled: boolean } | null } };

export type DeleteStockGroupMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type DeleteStockGroupMutation = { __typename?: 'Mutation', deleteStockGroup: { __typename?: 'MutationStockGroupResponse', stockGroup?: { __typename?: 'StockGroup', id: number, name: string, internalCode: string, enabled: boolean } | null } };

export type SetStockGroupStatusMutationVariables = Types.Exact<{
  input: Types.SetStockGroupStatusInput;
}>;


export type SetStockGroupStatusMutation = { __typename?: 'Mutation', setStockGroupStatus: { __typename?: 'MutationStockGroupResponse', stockGroup?: { __typename?: 'StockGroup', id: number, name: string, internalCode: string, enabled: boolean } | null } };

export type AddStoreToStockGroupMutationVariables = Types.Exact<{
  input: Types.AddStoreToStockGroupInput;
}>;


export type AddStoreToStockGroupMutation = { __typename?: 'Mutation', addStoreToStockGroup: { __typename?: 'MutationStockGroupResponse', stockGroup?: { __typename?: 'StockGroup', id: number, name: string, internalCode: string, enabled: boolean } | null } };

export type RemoveStoreFromStockGroupMutationVariables = Types.Exact<{
  input: Types.RemoveStoreFromStockGroupInput;
}>;


export type RemoveStoreFromStockGroupMutation = { __typename?: 'Mutation', removeStoreFromStockGroup: { __typename?: 'MutationStockGroupResponse', stockGroup?: { __typename?: 'StockGroup', id: number, name: string, internalCode: string, enabled: boolean } | null } };


export const FindStockGroupsDocument = gql`
    query FindStockGroups($input: FindStockGroupsInput!) {
  findStockGroups(findStockGroupsInput: $input) {
    stockGroups {
      id
      name
      internalCode
      enabled
    }
    pagination {
      pageBased {
        count
      }
    }
  }
}
    `;

/**
 * __useFindStockGroupsQuery__
 *
 * To run a query within a React component, call `useFindStockGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindStockGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindStockGroupsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindStockGroupsQuery(baseOptions: Apollo.QueryHookOptions<FindStockGroupsQuery, FindStockGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindStockGroupsQuery, FindStockGroupsQueryVariables>(FindStockGroupsDocument, options);
      }
export function useFindStockGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindStockGroupsQuery, FindStockGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindStockGroupsQuery, FindStockGroupsQueryVariables>(FindStockGroupsDocument, options);
        }
export type FindStockGroupsQueryHookResult = ReturnType<typeof useFindStockGroupsQuery>;
export type FindStockGroupsLazyQueryHookResult = ReturnType<typeof useFindStockGroupsLazyQuery>;
export type FindStockGroupsQueryResult = Apollo.QueryResult<FindStockGroupsQuery, FindStockGroupsQueryVariables>;
export const FindStoresInternalCodesInStockGroupDocument = gql`
    query FindStoresInternalCodesInStockGroup($input: FindStoresInternalCodesInStockGroupInput!) {
  findStoresInternalCodesInStockGroup(
    findStoresInternalCodesInStockGroupInput: $input
  ) {
    storeInternalCodes
    pagination {
      cursorBased {
        firstCursor
        lastCursor
        hasNextPage
        hasPreviousPage
      }
      pageBased {
        count
      }
    }
  }
}
    `;

/**
 * __useFindStoresInternalCodesInStockGroupQuery__
 *
 * To run a query within a React component, call `useFindStoresInternalCodesInStockGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindStoresInternalCodesInStockGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindStoresInternalCodesInStockGroupQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindStoresInternalCodesInStockGroupQuery(baseOptions: Apollo.QueryHookOptions<FindStoresInternalCodesInStockGroupQuery, FindStoresInternalCodesInStockGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindStoresInternalCodesInStockGroupQuery, FindStoresInternalCodesInStockGroupQueryVariables>(FindStoresInternalCodesInStockGroupDocument, options);
      }
export function useFindStoresInternalCodesInStockGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindStoresInternalCodesInStockGroupQuery, FindStoresInternalCodesInStockGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindStoresInternalCodesInStockGroupQuery, FindStoresInternalCodesInStockGroupQueryVariables>(FindStoresInternalCodesInStockGroupDocument, options);
        }
export type FindStoresInternalCodesInStockGroupQueryHookResult = ReturnType<typeof useFindStoresInternalCodesInStockGroupQuery>;
export type FindStoresInternalCodesInStockGroupLazyQueryHookResult = ReturnType<typeof useFindStoresInternalCodesInStockGroupLazyQuery>;
export type FindStoresInternalCodesInStockGroupQueryResult = Apollo.QueryResult<FindStoresInternalCodesInStockGroupQuery, FindStoresInternalCodesInStockGroupQueryVariables>;
export const CreateStockGroupDocument = gql`
    mutation CreateStockGroup($input: CreateStockGroupInput!) {
  createStockGroup(createStockGroupInput: $input) {
    stockGroup {
      id
      name
      internalCode
      enabled
    }
  }
}
    `;
export type CreateStockGroupMutationFn = Apollo.MutationFunction<CreateStockGroupMutation, CreateStockGroupMutationVariables>;

/**
 * __useCreateStockGroupMutation__
 *
 * To run a mutation, you first call `useCreateStockGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockGroupMutation, { data, loading, error }] = useCreateStockGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStockGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateStockGroupMutation, CreateStockGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStockGroupMutation, CreateStockGroupMutationVariables>(CreateStockGroupDocument, options);
      }
export type CreateStockGroupMutationHookResult = ReturnType<typeof useCreateStockGroupMutation>;
export type CreateStockGroupMutationResult = Apollo.MutationResult<CreateStockGroupMutation>;
export type CreateStockGroupMutationOptions = Apollo.BaseMutationOptions<CreateStockGroupMutation, CreateStockGroupMutationVariables>;
export const UpdateStockGroupDocument = gql`
    mutation UpdateStockGroup($input: UpdateStockGroupInput!) {
  updateStockGroup(updateStockGroupInput: $input) {
    stockGroup {
      id
      name
      internalCode
      enabled
    }
  }
}
    `;
export type UpdateStockGroupMutationFn = Apollo.MutationFunction<UpdateStockGroupMutation, UpdateStockGroupMutationVariables>;

/**
 * __useUpdateStockGroupMutation__
 *
 * To run a mutation, you first call `useUpdateStockGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStockGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStockGroupMutation, { data, loading, error }] = useUpdateStockGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStockGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStockGroupMutation, UpdateStockGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStockGroupMutation, UpdateStockGroupMutationVariables>(UpdateStockGroupDocument, options);
      }
export type UpdateStockGroupMutationHookResult = ReturnType<typeof useUpdateStockGroupMutation>;
export type UpdateStockGroupMutationResult = Apollo.MutationResult<UpdateStockGroupMutation>;
export type UpdateStockGroupMutationOptions = Apollo.BaseMutationOptions<UpdateStockGroupMutation, UpdateStockGroupMutationVariables>;
export const DeleteStockGroupDocument = gql`
    mutation DeleteStockGroup($id: Int!) {
  deleteStockGroup(id: $id) {
    stockGroup {
      id
      name
      internalCode
      enabled
    }
  }
}
    `;
export type DeleteStockGroupMutationFn = Apollo.MutationFunction<DeleteStockGroupMutation, DeleteStockGroupMutationVariables>;

/**
 * __useDeleteStockGroupMutation__
 *
 * To run a mutation, you first call `useDeleteStockGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStockGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStockGroupMutation, { data, loading, error }] = useDeleteStockGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStockGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStockGroupMutation, DeleteStockGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStockGroupMutation, DeleteStockGroupMutationVariables>(DeleteStockGroupDocument, options);
      }
export type DeleteStockGroupMutationHookResult = ReturnType<typeof useDeleteStockGroupMutation>;
export type DeleteStockGroupMutationResult = Apollo.MutationResult<DeleteStockGroupMutation>;
export type DeleteStockGroupMutationOptions = Apollo.BaseMutationOptions<DeleteStockGroupMutation, DeleteStockGroupMutationVariables>;
export const SetStockGroupStatusDocument = gql`
    mutation SetStockGroupStatus($input: SetStockGroupStatusInput!) {
  setStockGroupStatus(setStockGroupStatusInput: $input) {
    stockGroup {
      id
      name
      internalCode
      enabled
    }
  }
}
    `;
export type SetStockGroupStatusMutationFn = Apollo.MutationFunction<SetStockGroupStatusMutation, SetStockGroupStatusMutationVariables>;

/**
 * __useSetStockGroupStatusMutation__
 *
 * To run a mutation, you first call `useSetStockGroupStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetStockGroupStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setStockGroupStatusMutation, { data, loading, error }] = useSetStockGroupStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetStockGroupStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetStockGroupStatusMutation, SetStockGroupStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetStockGroupStatusMutation, SetStockGroupStatusMutationVariables>(SetStockGroupStatusDocument, options);
      }
export type SetStockGroupStatusMutationHookResult = ReturnType<typeof useSetStockGroupStatusMutation>;
export type SetStockGroupStatusMutationResult = Apollo.MutationResult<SetStockGroupStatusMutation>;
export type SetStockGroupStatusMutationOptions = Apollo.BaseMutationOptions<SetStockGroupStatusMutation, SetStockGroupStatusMutationVariables>;
export const AddStoreToStockGroupDocument = gql`
    mutation AddStoreToStockGroup($input: AddStoreToStockGroupInput!) {
  addStoreToStockGroup(addStoreToStockGroupInput: $input) {
    stockGroup {
      id
      name
      internalCode
      enabled
    }
  }
}
    `;
export type AddStoreToStockGroupMutationFn = Apollo.MutationFunction<AddStoreToStockGroupMutation, AddStoreToStockGroupMutationVariables>;

/**
 * __useAddStoreToStockGroupMutation__
 *
 * To run a mutation, you first call `useAddStoreToStockGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStoreToStockGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStoreToStockGroupMutation, { data, loading, error }] = useAddStoreToStockGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStoreToStockGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddStoreToStockGroupMutation, AddStoreToStockGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStoreToStockGroupMutation, AddStoreToStockGroupMutationVariables>(AddStoreToStockGroupDocument, options);
      }
export type AddStoreToStockGroupMutationHookResult = ReturnType<typeof useAddStoreToStockGroupMutation>;
export type AddStoreToStockGroupMutationResult = Apollo.MutationResult<AddStoreToStockGroupMutation>;
export type AddStoreToStockGroupMutationOptions = Apollo.BaseMutationOptions<AddStoreToStockGroupMutation, AddStoreToStockGroupMutationVariables>;
export const RemoveStoreFromStockGroupDocument = gql`
    mutation RemoveStoreFromStockGroup($input: RemoveStoreFromStockGroupInput!) {
  removeStoreFromStockGroup(removeStoreFromStockGroupInput: $input) {
    stockGroup {
      id
      name
      internalCode
      enabled
    }
  }
}
    `;
export type RemoveStoreFromStockGroupMutationFn = Apollo.MutationFunction<RemoveStoreFromStockGroupMutation, RemoveStoreFromStockGroupMutationVariables>;

/**
 * __useRemoveStoreFromStockGroupMutation__
 *
 * To run a mutation, you first call `useRemoveStoreFromStockGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStoreFromStockGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStoreFromStockGroupMutation, { data, loading, error }] = useRemoveStoreFromStockGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveStoreFromStockGroupMutation(baseOptions?: Apollo.MutationHookOptions<RemoveStoreFromStockGroupMutation, RemoveStoreFromStockGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveStoreFromStockGroupMutation, RemoveStoreFromStockGroupMutationVariables>(RemoveStoreFromStockGroupDocument, options);
      }
export type RemoveStoreFromStockGroupMutationHookResult = ReturnType<typeof useRemoveStoreFromStockGroupMutation>;
export type RemoveStoreFromStockGroupMutationResult = Apollo.MutationResult<RemoveStoreFromStockGroupMutation>;
export type RemoveStoreFromStockGroupMutationOptions = Apollo.BaseMutationOptions<RemoveStoreFromStockGroupMutation, RemoveStoreFromStockGroupMutationVariables>;
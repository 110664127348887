import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindPriceListsQueryVariables = Types.Exact<{
  findAllPriceListsInput?: Types.InputMaybe<Types.FindAllPriceListsInput>;
}>;


export type FindPriceListsQuery = { __typename?: 'Query', findPriceLists: { __typename?: 'PagedPriceList', priceLists: Array<{ __typename?: 'PriceList', id: number, internalCode?: string | null, enabled: boolean, createdAt: number, name: string, updatedAt: number } | null>, pagination: { __typename?: 'PageInfo', pageBased?: { __typename?: 'PageBasedInfo', count: number } | null } } };

export type FindPriceListByStoreIdQueryVariables = Types.Exact<{
  storeId: Types.Scalars['Int'];
}>;


export type FindPriceListByStoreIdQuery = { __typename?: 'Query', findPriceListByStoreId?: { __typename?: 'PriceList', id: number, name: string, internalCode?: string | null, supplierId: number, createdAt: number, updatedAt: number, enabled: boolean } | null };

export type CreatePriceListMutationVariables = Types.Exact<{
  createPriceListInput: Types.CreatePriceListInput;
}>;


export type CreatePriceListMutation = { __typename?: 'Mutation', createPriceList: { __typename?: 'MutationPriceListResponse', priceList: { __typename?: 'PriceList', name: string, id: number } } };

export type FindInternalCodesInPriceListQueryVariables = Types.Exact<{
  findStoresInternalCodesInPriceListInput: Types.FindStoresInternalCodesInPriceListInput;
}>;


export type FindInternalCodesInPriceListQuery = { __typename?: 'Query', findStoresInternalCodesInPriceList: { __typename?: 'PagedStoreInternalCodes', storeInternalCodes: Array<string | null>, pagination: { __typename?: 'PageInfo', pageBased?: { __typename?: 'PageBasedInfo', count: number } | null } } };

export type AddStoreToPriceListMutationVariables = Types.Exact<{
  addStoreToPriceListInput: Types.AddStoreToPriceListInput;
}>;


export type AddStoreToPriceListMutation = { __typename?: 'Mutation', addStoreToPriceList: { __typename?: 'MutationPriceListResponse', priceList: { __typename?: 'PriceList', createdAt: number, enabled: boolean, id: number, internalCode?: string | null, name: string, updatedAt: number } } };

export type RemoveStoreFromPriceListMutationVariables = Types.Exact<{
  removeStoreFromPriceListInput: Types.RemoveStoreFromPriceListInput;
}>;


export type RemoveStoreFromPriceListMutation = { __typename?: 'Mutation', removeStoreFromPriceList: { __typename?: 'MutationPriceListResponse', priceList: { __typename?: 'PriceList', id: number, name: string } } };

export type FindProductsInPriceListQueryVariables = Types.Exact<{
  findProductsInPriceListInput: Types.FindProductsInPriceListInput;
}>;


export type FindProductsInPriceListQuery = { __typename?: 'Query', findProductsInPriceList: { __typename?: 'PagedProductOnPriceList', products: Array<{ __typename?: 'PriceListProductResponse', enabled: boolean, id: number, image: string, name: string, identificationCode: string, displayProducts: Array<{ __typename?: 'DisplayProductResponse', enabled: boolean, id: number, units: number, internalCode?: string | null, price?: { __typename?: 'Money', amount: number, currency: string, scale: number, formatted?: { __typename?: 'MoneyFormat', text: string, value: number } | null } | null } | null> } | null>, pagination: { __typename?: 'PageInfo', pageBased?: { __typename?: 'PageBasedInfo', count: number } | null } } };

export type AddProductToPriceListMutationVariables = Types.Exact<{
  addProductToPriceListInput: Types.AddProductToPriceListInput;
}>;


export type AddProductToPriceListMutation = { __typename?: 'Mutation', addProductToPriceList: { __typename?: 'MutationPriceListResponse', priceList: { __typename?: 'PriceList', createdAt: number, enabled: boolean, id: number, internalCode?: string | null, name: string, supplierId: number, updatedAt: number } } };

export type UpdateProductOnPriceListMutationVariables = Types.Exact<{
  updateProductOnPriceListInput: Types.UpdateProductOnPriceListInput;
}>;


export type UpdateProductOnPriceListMutation = { __typename?: 'Mutation', updateProductOnPriceList: { __typename?: 'MutationPriceListResponse', priceList: { __typename?: 'PriceList', createdAt: number, enabled: boolean, id: number, internalCode?: string | null, name: string, supplierId: number, updatedAt: number } } };

export type RemoveProductFromPriceListMutationVariables = Types.Exact<{
  removeProductFromPriceListInput?: Types.InputMaybe<Types.RemoveProductFromPriceListInput>;
}>;


export type RemoveProductFromPriceListMutation = { __typename?: 'Mutation', removeProductFromPriceList: { __typename?: 'MutationPriceListResponse', priceList: { __typename?: 'PriceList', createdAt: number, enabled: boolean, id: number, internalCode?: string | null, name: string, supplierId: number, updatedAt: number } } };

export type UpdatePriceListMutationVariables = Types.Exact<{
  updatePriceListInput: Types.UpdatePriceListInput;
}>;


export type UpdatePriceListMutation = { __typename?: 'Mutation', updatePriceList: { __typename?: 'MutationPriceListResponse', priceList: { __typename?: 'PriceList', id: number, internalCode?: string | null, name: string, enabled: boolean, createdAt: number, updatedAt: number } } };

export type SetPriceListStatusMutationVariables = Types.Exact<{
  setPriceListStatusInput: Types.SetPriceListStatusInput;
}>;


export type SetPriceListStatusMutation = { __typename?: 'Mutation', setPriceListStatus: { __typename?: 'MutationPriceListResponse', priceList: { __typename?: 'PriceList', createdAt: number, enabled: boolean, id: number, internalCode?: string | null, name: string } } };


export const FindPriceListsDocument = gql`
    query FindPriceLists($findAllPriceListsInput: FindAllPriceListsInput) {
  findPriceLists(findAllPriceListsInput: $findAllPriceListsInput) {
    priceLists {
      id
      internalCode
      enabled
      createdAt
      name
      updatedAt
    }
    pagination {
      pageBased {
        count
      }
    }
  }
}
    `;

/**
 * __useFindPriceListsQuery__
 *
 * To run a query within a React component, call `useFindPriceListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPriceListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPriceListsQuery({
 *   variables: {
 *      findAllPriceListsInput: // value for 'findAllPriceListsInput'
 *   },
 * });
 */
export function useFindPriceListsQuery(baseOptions?: Apollo.QueryHookOptions<FindPriceListsQuery, FindPriceListsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindPriceListsQuery, FindPriceListsQueryVariables>(FindPriceListsDocument, options);
      }
export function useFindPriceListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindPriceListsQuery, FindPriceListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindPriceListsQuery, FindPriceListsQueryVariables>(FindPriceListsDocument, options);
        }
export type FindPriceListsQueryHookResult = ReturnType<typeof useFindPriceListsQuery>;
export type FindPriceListsLazyQueryHookResult = ReturnType<typeof useFindPriceListsLazyQuery>;
export type FindPriceListsQueryResult = Apollo.QueryResult<FindPriceListsQuery, FindPriceListsQueryVariables>;
export const FindPriceListByStoreIdDocument = gql`
    query FindPriceListByStoreId($storeId: Int!) {
  findPriceListByStoreId(id: $storeId) {
    id
    name
    internalCode
    supplierId
    createdAt
    updatedAt
    enabled
  }
}
    `;

/**
 * __useFindPriceListByStoreIdQuery__
 *
 * To run a query within a React component, call `useFindPriceListByStoreIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPriceListByStoreIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPriceListByStoreIdQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useFindPriceListByStoreIdQuery(baseOptions: Apollo.QueryHookOptions<FindPriceListByStoreIdQuery, FindPriceListByStoreIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindPriceListByStoreIdQuery, FindPriceListByStoreIdQueryVariables>(FindPriceListByStoreIdDocument, options);
      }
export function useFindPriceListByStoreIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindPriceListByStoreIdQuery, FindPriceListByStoreIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindPriceListByStoreIdQuery, FindPriceListByStoreIdQueryVariables>(FindPriceListByStoreIdDocument, options);
        }
export type FindPriceListByStoreIdQueryHookResult = ReturnType<typeof useFindPriceListByStoreIdQuery>;
export type FindPriceListByStoreIdLazyQueryHookResult = ReturnType<typeof useFindPriceListByStoreIdLazyQuery>;
export type FindPriceListByStoreIdQueryResult = Apollo.QueryResult<FindPriceListByStoreIdQuery, FindPriceListByStoreIdQueryVariables>;
export const CreatePriceListDocument = gql`
    mutation CreatePriceList($createPriceListInput: CreatePriceListInput!) {
  createPriceList(createPriceListInput: $createPriceListInput) {
    priceList {
      name
      id
    }
  }
}
    `;
export type CreatePriceListMutationFn = Apollo.MutationFunction<CreatePriceListMutation, CreatePriceListMutationVariables>;

/**
 * __useCreatePriceListMutation__
 *
 * To run a mutation, you first call `useCreatePriceListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePriceListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPriceListMutation, { data, loading, error }] = useCreatePriceListMutation({
 *   variables: {
 *      createPriceListInput: // value for 'createPriceListInput'
 *   },
 * });
 */
export function useCreatePriceListMutation(baseOptions?: Apollo.MutationHookOptions<CreatePriceListMutation, CreatePriceListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePriceListMutation, CreatePriceListMutationVariables>(CreatePriceListDocument, options);
      }
export type CreatePriceListMutationHookResult = ReturnType<typeof useCreatePriceListMutation>;
export type CreatePriceListMutationResult = Apollo.MutationResult<CreatePriceListMutation>;
export type CreatePriceListMutationOptions = Apollo.BaseMutationOptions<CreatePriceListMutation, CreatePriceListMutationVariables>;
export const FindInternalCodesInPriceListDocument = gql`
    query FindInternalCodesInPriceList($findStoresInternalCodesInPriceListInput: FindStoresInternalCodesInPriceListInput!) {
  findStoresInternalCodesInPriceList(
    findStoresInternalCodesInPriceListInput: $findStoresInternalCodesInPriceListInput
  ) {
    storeInternalCodes
    pagination {
      pageBased {
        count
      }
    }
  }
}
    `;

/**
 * __useFindInternalCodesInPriceListQuery__
 *
 * To run a query within a React component, call `useFindInternalCodesInPriceListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindInternalCodesInPriceListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindInternalCodesInPriceListQuery({
 *   variables: {
 *      findStoresInternalCodesInPriceListInput: // value for 'findStoresInternalCodesInPriceListInput'
 *   },
 * });
 */
export function useFindInternalCodesInPriceListQuery(baseOptions: Apollo.QueryHookOptions<FindInternalCodesInPriceListQuery, FindInternalCodesInPriceListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindInternalCodesInPriceListQuery, FindInternalCodesInPriceListQueryVariables>(FindInternalCodesInPriceListDocument, options);
      }
export function useFindInternalCodesInPriceListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindInternalCodesInPriceListQuery, FindInternalCodesInPriceListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindInternalCodesInPriceListQuery, FindInternalCodesInPriceListQueryVariables>(FindInternalCodesInPriceListDocument, options);
        }
export type FindInternalCodesInPriceListQueryHookResult = ReturnType<typeof useFindInternalCodesInPriceListQuery>;
export type FindInternalCodesInPriceListLazyQueryHookResult = ReturnType<typeof useFindInternalCodesInPriceListLazyQuery>;
export type FindInternalCodesInPriceListQueryResult = Apollo.QueryResult<FindInternalCodesInPriceListQuery, FindInternalCodesInPriceListQueryVariables>;
export const AddStoreToPriceListDocument = gql`
    mutation AddStoreToPriceList($addStoreToPriceListInput: AddStoreToPriceListInput!) {
  addStoreToPriceList(addStoreToPriceListInput: $addStoreToPriceListInput) {
    priceList {
      createdAt
      enabled
      id
      internalCode
      name
      updatedAt
    }
  }
}
    `;
export type AddStoreToPriceListMutationFn = Apollo.MutationFunction<AddStoreToPriceListMutation, AddStoreToPriceListMutationVariables>;

/**
 * __useAddStoreToPriceListMutation__
 *
 * To run a mutation, you first call `useAddStoreToPriceListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStoreToPriceListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStoreToPriceListMutation, { data, loading, error }] = useAddStoreToPriceListMutation({
 *   variables: {
 *      addStoreToPriceListInput: // value for 'addStoreToPriceListInput'
 *   },
 * });
 */
export function useAddStoreToPriceListMutation(baseOptions?: Apollo.MutationHookOptions<AddStoreToPriceListMutation, AddStoreToPriceListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStoreToPriceListMutation, AddStoreToPriceListMutationVariables>(AddStoreToPriceListDocument, options);
      }
export type AddStoreToPriceListMutationHookResult = ReturnType<typeof useAddStoreToPriceListMutation>;
export type AddStoreToPriceListMutationResult = Apollo.MutationResult<AddStoreToPriceListMutation>;
export type AddStoreToPriceListMutationOptions = Apollo.BaseMutationOptions<AddStoreToPriceListMutation, AddStoreToPriceListMutationVariables>;
export const RemoveStoreFromPriceListDocument = gql`
    mutation RemoveStoreFromPriceList($removeStoreFromPriceListInput: RemoveStoreFromPriceListInput!) {
  removeStoreFromPriceList(
    removeStoreFromPriceListInput: $removeStoreFromPriceListInput
  ) {
    priceList {
      id
      name
    }
  }
}
    `;
export type RemoveStoreFromPriceListMutationFn = Apollo.MutationFunction<RemoveStoreFromPriceListMutation, RemoveStoreFromPriceListMutationVariables>;

/**
 * __useRemoveStoreFromPriceListMutation__
 *
 * To run a mutation, you first call `useRemoveStoreFromPriceListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStoreFromPriceListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStoreFromPriceListMutation, { data, loading, error }] = useRemoveStoreFromPriceListMutation({
 *   variables: {
 *      removeStoreFromPriceListInput: // value for 'removeStoreFromPriceListInput'
 *   },
 * });
 */
export function useRemoveStoreFromPriceListMutation(baseOptions?: Apollo.MutationHookOptions<RemoveStoreFromPriceListMutation, RemoveStoreFromPriceListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveStoreFromPriceListMutation, RemoveStoreFromPriceListMutationVariables>(RemoveStoreFromPriceListDocument, options);
      }
export type RemoveStoreFromPriceListMutationHookResult = ReturnType<typeof useRemoveStoreFromPriceListMutation>;
export type RemoveStoreFromPriceListMutationResult = Apollo.MutationResult<RemoveStoreFromPriceListMutation>;
export type RemoveStoreFromPriceListMutationOptions = Apollo.BaseMutationOptions<RemoveStoreFromPriceListMutation, RemoveStoreFromPriceListMutationVariables>;
export const FindProductsInPriceListDocument = gql`
    query FindProductsInPriceList($findProductsInPriceListInput: FindProductsInPriceListInput!) {
  findProductsInPriceList(
    findProductsInPriceListInput: $findProductsInPriceListInput
  ) {
    products {
      displayProducts {
        enabled
        id
        price {
          amount
          currency
          scale
          formatted {
            text
            value
          }
        }
        units
        internalCode
      }
      enabled
      id
      image
      name
      identificationCode
    }
    pagination {
      pageBased {
        count
      }
    }
  }
}
    `;

/**
 * __useFindProductsInPriceListQuery__
 *
 * To run a query within a React component, call `useFindProductsInPriceListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindProductsInPriceListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindProductsInPriceListQuery({
 *   variables: {
 *      findProductsInPriceListInput: // value for 'findProductsInPriceListInput'
 *   },
 * });
 */
export function useFindProductsInPriceListQuery(baseOptions: Apollo.QueryHookOptions<FindProductsInPriceListQuery, FindProductsInPriceListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindProductsInPriceListQuery, FindProductsInPriceListQueryVariables>(FindProductsInPriceListDocument, options);
      }
export function useFindProductsInPriceListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindProductsInPriceListQuery, FindProductsInPriceListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindProductsInPriceListQuery, FindProductsInPriceListQueryVariables>(FindProductsInPriceListDocument, options);
        }
export type FindProductsInPriceListQueryHookResult = ReturnType<typeof useFindProductsInPriceListQuery>;
export type FindProductsInPriceListLazyQueryHookResult = ReturnType<typeof useFindProductsInPriceListLazyQuery>;
export type FindProductsInPriceListQueryResult = Apollo.QueryResult<FindProductsInPriceListQuery, FindProductsInPriceListQueryVariables>;
export const AddProductToPriceListDocument = gql`
    mutation AddProductToPriceList($addProductToPriceListInput: AddProductToPriceListInput!) {
  addProductToPriceList(addProductToPriceListInput: $addProductToPriceListInput) {
    priceList {
      createdAt
      enabled
      id
      internalCode
      name
      supplierId
      updatedAt
    }
  }
}
    `;
export type AddProductToPriceListMutationFn = Apollo.MutationFunction<AddProductToPriceListMutation, AddProductToPriceListMutationVariables>;

/**
 * __useAddProductToPriceListMutation__
 *
 * To run a mutation, you first call `useAddProductToPriceListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductToPriceListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductToPriceListMutation, { data, loading, error }] = useAddProductToPriceListMutation({
 *   variables: {
 *      addProductToPriceListInput: // value for 'addProductToPriceListInput'
 *   },
 * });
 */
export function useAddProductToPriceListMutation(baseOptions?: Apollo.MutationHookOptions<AddProductToPriceListMutation, AddProductToPriceListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddProductToPriceListMutation, AddProductToPriceListMutationVariables>(AddProductToPriceListDocument, options);
      }
export type AddProductToPriceListMutationHookResult = ReturnType<typeof useAddProductToPriceListMutation>;
export type AddProductToPriceListMutationResult = Apollo.MutationResult<AddProductToPriceListMutation>;
export type AddProductToPriceListMutationOptions = Apollo.BaseMutationOptions<AddProductToPriceListMutation, AddProductToPriceListMutationVariables>;
export const UpdateProductOnPriceListDocument = gql`
    mutation UpdateProductOnPriceList($updateProductOnPriceListInput: UpdateProductOnPriceListInput!) {
  updateProductOnPriceList(
    updateProductOnPriceListInput: $updateProductOnPriceListInput
  ) {
    priceList {
      createdAt
      enabled
      id
      internalCode
      name
      supplierId
      updatedAt
    }
  }
}
    `;
export type UpdateProductOnPriceListMutationFn = Apollo.MutationFunction<UpdateProductOnPriceListMutation, UpdateProductOnPriceListMutationVariables>;

/**
 * __useUpdateProductOnPriceListMutation__
 *
 * To run a mutation, you first call `useUpdateProductOnPriceListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductOnPriceListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductOnPriceListMutation, { data, loading, error }] = useUpdateProductOnPriceListMutation({
 *   variables: {
 *      updateProductOnPriceListInput: // value for 'updateProductOnPriceListInput'
 *   },
 * });
 */
export function useUpdateProductOnPriceListMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductOnPriceListMutation, UpdateProductOnPriceListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductOnPriceListMutation, UpdateProductOnPriceListMutationVariables>(UpdateProductOnPriceListDocument, options);
      }
export type UpdateProductOnPriceListMutationHookResult = ReturnType<typeof useUpdateProductOnPriceListMutation>;
export type UpdateProductOnPriceListMutationResult = Apollo.MutationResult<UpdateProductOnPriceListMutation>;
export type UpdateProductOnPriceListMutationOptions = Apollo.BaseMutationOptions<UpdateProductOnPriceListMutation, UpdateProductOnPriceListMutationVariables>;
export const RemoveProductFromPriceListDocument = gql`
    mutation RemoveProductFromPriceList($removeProductFromPriceListInput: RemoveProductFromPriceListInput) {
  removeProductFromPriceList(
    removeProductFromPriceListInput: $removeProductFromPriceListInput
  ) {
    priceList {
      createdAt
      enabled
      id
      internalCode
      name
      supplierId
      updatedAt
    }
  }
}
    `;
export type RemoveProductFromPriceListMutationFn = Apollo.MutationFunction<RemoveProductFromPriceListMutation, RemoveProductFromPriceListMutationVariables>;

/**
 * __useRemoveProductFromPriceListMutation__
 *
 * To run a mutation, you first call `useRemoveProductFromPriceListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProductFromPriceListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProductFromPriceListMutation, { data, loading, error }] = useRemoveProductFromPriceListMutation({
 *   variables: {
 *      removeProductFromPriceListInput: // value for 'removeProductFromPriceListInput'
 *   },
 * });
 */
export function useRemoveProductFromPriceListMutation(baseOptions?: Apollo.MutationHookOptions<RemoveProductFromPriceListMutation, RemoveProductFromPriceListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveProductFromPriceListMutation, RemoveProductFromPriceListMutationVariables>(RemoveProductFromPriceListDocument, options);
      }
export type RemoveProductFromPriceListMutationHookResult = ReturnType<typeof useRemoveProductFromPriceListMutation>;
export type RemoveProductFromPriceListMutationResult = Apollo.MutationResult<RemoveProductFromPriceListMutation>;
export type RemoveProductFromPriceListMutationOptions = Apollo.BaseMutationOptions<RemoveProductFromPriceListMutation, RemoveProductFromPriceListMutationVariables>;
export const UpdatePriceListDocument = gql`
    mutation UpdatePriceList($updatePriceListInput: UpdatePriceListInput!) {
  updatePriceList(updatePriceListInput: $updatePriceListInput) {
    priceList {
      id
      internalCode
      name
      enabled
      createdAt
      updatedAt
    }
  }
}
    `;
export type UpdatePriceListMutationFn = Apollo.MutationFunction<UpdatePriceListMutation, UpdatePriceListMutationVariables>;

/**
 * __useUpdatePriceListMutation__
 *
 * To run a mutation, you first call `useUpdatePriceListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePriceListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePriceListMutation, { data, loading, error }] = useUpdatePriceListMutation({
 *   variables: {
 *      updatePriceListInput: // value for 'updatePriceListInput'
 *   },
 * });
 */
export function useUpdatePriceListMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePriceListMutation, UpdatePriceListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePriceListMutation, UpdatePriceListMutationVariables>(UpdatePriceListDocument, options);
      }
export type UpdatePriceListMutationHookResult = ReturnType<typeof useUpdatePriceListMutation>;
export type UpdatePriceListMutationResult = Apollo.MutationResult<UpdatePriceListMutation>;
export type UpdatePriceListMutationOptions = Apollo.BaseMutationOptions<UpdatePriceListMutation, UpdatePriceListMutationVariables>;
export const SetPriceListStatusDocument = gql`
    mutation SetPriceListStatus($setPriceListStatusInput: SetPriceListStatusInput!) {
  setPriceListStatus(setPriceListStatusInput: $setPriceListStatusInput) {
    priceList {
      createdAt
      enabled
      id
      internalCode
      name
    }
  }
}
    `;
export type SetPriceListStatusMutationFn = Apollo.MutationFunction<SetPriceListStatusMutation, SetPriceListStatusMutationVariables>;

/**
 * __useSetPriceListStatusMutation__
 *
 * To run a mutation, you first call `useSetPriceListStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPriceListStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPriceListStatusMutation, { data, loading, error }] = useSetPriceListStatusMutation({
 *   variables: {
 *      setPriceListStatusInput: // value for 'setPriceListStatusInput'
 *   },
 * });
 */
export function useSetPriceListStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetPriceListStatusMutation, SetPriceListStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPriceListStatusMutation, SetPriceListStatusMutationVariables>(SetPriceListStatusDocument, options);
      }
export type SetPriceListStatusMutationHookResult = ReturnType<typeof useSetPriceListStatusMutation>;
export type SetPriceListStatusMutationResult = Apollo.MutationResult<SetPriceListStatusMutation>;
export type SetPriceListStatusMutationOptions = Apollo.BaseMutationOptions<SetPriceListStatusMutation, SetPriceListStatusMutationVariables>;
import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindPromotionsTotalsQueryVariables = Types.Exact<{
  input: Types.FindPromotionsTotalsForStoreInput;
}>;


export type FindPromotionsTotalsQuery = { __typename?: 'Query', findPromotionsTotalsForStore: Array<{ __typename?: 'PromotionTotals', count: number, type: string } | null> };


export const FindPromotionsTotalsDocument = gql`
    query FindPromotionsTotals($input: FindPromotionsTotalsForStoreInput!) {
  findPromotionsTotalsForStore(input: $input) {
    count
    type
  }
}
    `;

/**
 * __useFindPromotionsTotalsQuery__
 *
 * To run a query within a React component, call `useFindPromotionsTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPromotionsTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPromotionsTotalsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindPromotionsTotalsQuery(baseOptions: Apollo.QueryHookOptions<FindPromotionsTotalsQuery, FindPromotionsTotalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindPromotionsTotalsQuery, FindPromotionsTotalsQueryVariables>(FindPromotionsTotalsDocument, options);
      }
export function useFindPromotionsTotalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindPromotionsTotalsQuery, FindPromotionsTotalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindPromotionsTotalsQuery, FindPromotionsTotalsQueryVariables>(FindPromotionsTotalsDocument, options);
        }
export type FindPromotionsTotalsQueryHookResult = ReturnType<typeof useFindPromotionsTotalsQuery>;
export type FindPromotionsTotalsLazyQueryHookResult = ReturnType<typeof useFindPromotionsTotalsLazyQuery>;
export type FindPromotionsTotalsQueryResult = Apollo.QueryResult<FindPromotionsTotalsQuery, FindPromotionsTotalsQueryVariables>;